import React from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  List,
  ListItemText,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Pre = styled("pre")(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Roboto",
}));

export default function Partners() {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          p: { xs: 3, md: 6 },
        }}
      >
        <Typography variant="h3">Our Partners</Typography>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h5" gutterBottom>
          To provide you with the best rates, we are partnered with these great
          companies:
        </Typography>
        <Grid container>
          <Grid item xs={12} md={12} lg={4}>
            <Pre>
              {`2insure4less
AARP
Accuquote
Acordia
Acquire Health
Admediary
Admiral Life
Aetna
Affiliated Benefits Group
Affiliated Brokers Insurance
Affinity Health Plan
Affordable Health Direct
Affordable Life USA
AFLAC
Agency Incline
Agent Insider
Agentra Healthcare
Agile Health
AHCP
AIA Direct
AIG
AIG Direct
Aliera Companies
Aliera Healthcare
All Web Leads
All Web Leads Company Allied Insurance
Alliance and Associates
Alliance Direct
Alliance Health
Alliance Insurance
Alliance National Health
Allied
Allied Coverage
Allied Health
Allied Insurance
Allied Insurance Partners
ALLWEB
AllWebLeads
Alpine Digital
Alpine Digital Group Inc.
Americans Choice Health
Americare
Americare Group
Americare/American Enterprise
America's Health Advisors
Americas Health Brokers
America's Health Care Plan
Americas Health Group
America's Insurance
Americas Trust
America's Trust
America's Trust Inc.
Amerigroup
AmeriHealth
Amerilife
AmeriPlan
Ameriprise
Ameriprise Financial Group
AmeriPro Funding Inc.
Ameriquote
Amica
Amica Insurance
ANPAC
Answer Financial
Anthem
Anthem BCBS
Anthem Blue Cross and Blue Shield
Anthem/BCBS
AON
Aon Corp.
Apollo Group
Apollo Insurance Group
Apollo Interactive
Aragon Advertising
Ardent Health Services
Assurance
Assurance Financial Group (Chris Payton)
Assurance IQ
Assurance IQ Inc.
Assurance Life
Assurant
Assure Media
Assured Benefits Direct
Assured Life
Astoria Company
Avant
AXA Advisors
AXA Insurance Group
Banker's Fidelity
Bankers Life and Casualty
Bankers Mortgage Corporation
Bankrate
Bankrate Inc.
Bankrate Insurance
BCBS
BCBS Georgia
BCBS of Alabama
BCBS of Florida
BCBS of Michigan
BCBS Ohio
Benefit Advisors
Benefit Concepts
BenefitLogix
Benepath
Benepath Health Insurance
BestQuotes
Blue Choice
Blue Cross
Blue Cross - Anthem
Blue Cross – Anthem
Blue Cross and Blue Shield
Blue Cross and Blue Shield Association
Blue Cross Blue Shield
Blue Cross Blue Shield of Nebraska
Blue Cross of South Carolina
Blue Cross/Blue Shield Association Companies
Blue Ink Digital
Blue Shield of CA
Blue Shield of California
Blue Sky
Blue Whale Media
Blue Wing Ads
BlueCross/Blue Shield Association Companies
Bluestar Solar Energy LLC
Boost
Boost Health
Boost Health Insurance
Call Center Partnerships
Call Lead Solutions
Call Trader LLC
Call4SeniorSavings.com
CEGE
Cege Media
Century Benefits
Choice Direct
Choice Health Insurance
Choice Insurance
Choice One Health
Chubb
Cigna
Colby Direct
Colonial
Colonial Insurance
Colonial Life & Accident Insurance Company
Colonial Penn
Compare.com
Connect Health Insurance
Connect Insurance Brands
Connect Medicare Advantage
Connect Medicare Supplement
Connect One Health
Consumer United
Consumers Alliance Processing Corporation
Contactability
Conventry
Coverage One
Coverage One Insurance
Coverage One Insurance Group
Coverage One Insurance Group LLC
Coverance Insurance Solutions
Creative Intellects
Crosspointe
Crosspointe Insurance
Crosspointe Insurance Advisors
Crosspointe Insurance and Financial Services LLC
Digital Market Media
Digital Media Solutions
Digital Media Solutions DBA Forte Media Solutions
Direct General
Direct Health
Direct Impact Leads
Direct Marketing Group
Direct Media Partners
DoublePositive
Efinancial
eHealth
eHealth Insurance
eHealthInsurance
eHealthInsurance Services Inc.
Ehealthinsurance.com
eLocal
eMarketing Media Group
EmblemHealth
EPCVIP
EPIQ
Esurance
e-Telequote
e-TeleQuote Insurance
EverQuote
EverQuote's marketing partners
Excel Electric Inc.
Excel Impact
ExcelImpact
Family First Insurance Advisors
Family First Insurance Advisors LLC
Family Life
Farmers Insurance
Fidelity and Guaranty Life
Fidelity Health Group
Fidelity Home Energy
Fidelity Insurance Company
Fidelity Investments Life
Fidelity Life
Fidelity National
Fidelity Security Life
Final Expense Direct
finalexpenseassistant.com
Florida Blue
Gainsco
Geber Life
GEICO
Geico Casualty
Geico General Insurance
Geico Indemnity
Gerber
Gerber Insurance Company
Gerber Life Insurance
Gerber Life Insurance Company
Getinsured.com
GetMeHealthCare.com
GetMeHealthInsurance.com
Globe Life
Globe Life Insurance Company
Globe Life Insurance Company of New York
GMAC Insurance
GMAC/NGIC
GoHealth
GoHealth - UE Calls
GoHealth Insurance
GoHealth.com
GoHealth/Norvax
GoHealthInsurance.com
GoMedicare
GoMedigap
Guaranteed Benefits`}
            </Pre>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Pre>
              {`Guaranteed Health
Guaranteed Health Options
Guaranteed Rate Inc.
Guaranty National
Guaranty National Insurance
Guardian
Guardian Health Group
Guardian Healthcare
Guardian Life
Hartford
Hartford AARP
Hartford Accident and Indemnity
Hartford Casualty Insurance
Hartford Fire & Casualty
Hartford Fire Insurance
Hartford Insurance Co of Illinois
Hartford Insurance Co of the Southeast
Hartford Life
Hartford Omni
Hartford Underwriters Insurance
Health Advisors
Health America
Health and Life Associates
Health and Life Plans of America
Health Benefits Center
Health Benefits Direct
Health Benefits One
Health Care Service Corp.
Health Care Solutions
Health Choice One
Health Connection Insurance
Health Corp USA
Health Coverage Fast
Health Enrollment Center
Health Exchange Agency
Health First Advisors
Health First Insurance Agency
Health First Plans
Health Heritage Advisors
Health Insurance Advantage
Health Insurance Advisors
Health Insurance Alliance
Health Insurance AllianceLLC
Health Insurance for Everyone
Health Insurance Guide
Health Insurance Innovations
Health Insurance of America
Health Insurance Services
Health Insurancely
Health Insure For You
Health IQ Insurance Services
Health IQ Insurance Services Inc.
Health Markets
Health Net
Health Network
Health Now New York Inc.
Health Option One
Health Partners of Philadelphia Inc.
Health Plan One
Health Plan OneLLC
Health Plan Services
Health Plans 4 Less
Health Plans America
Health Plans of America
Health Plus
Health Plus of America
Health Pocket
Health Reform Team
Health Savings Group
Health Sherpa
Health Solutions One
Health Solutions Team
Health Team One
Health Trust Financial
Health Works Agency LLC
Health1nsurance.com
Health1surance.com
HealthCare
Healthcare Advisors
HealthCare Alternatives
Healthcare Direct
Healthcare Inc.
Healthcare Solutions
Healthcare Solutions Team
Healthcare.com
HealthCompare
HealthCompare Insurance Services
HealthCompare Insurance Services Inc.
HealtheDeals
Healthedeals.com (“healthy deals”)
HealthInsurance.com
HealthInsurance.net
HealthInsurance.org
HealthIQ
Healthline Care
HealthMarketers
HealthMarkets
HealthMarkets / Insphere
HealthMarkets Inc.
HealthMarkets Insphere
HealthMarkets Insurance Agency
HealthMarkets/Insphere
HealthMatchup
HealthNet
HealthPartners
HealthPlan Advisors
HealthPlan Intermediaries Holdings
HealthPlan Services
HealthPlanMatchup
HealthPlanOne
HealthPlus of Michigan
HealthPocket
HealthQuote of NY
HealthQuoteInfo.com
HealthShare America
HealthShare American
HealthSpan
HealthSpire
HealthSpring
Healthy Halo
Heritage
Heritage Health Advisors
Heritage Health Group
Heritage Life Insurance Company
HII
HII – Starr Insurance
HIIQ
HIP Health Plan
HIP Insurance
Hippo
Hippo Financial
Hippo Insurance
HyperTarget
Hypertarget Marketing
I Health & Life Insurance
I Life & Health Insurance
iCan Benefit
iCan Benefit Group
iCan Group
Ideal Health Benefits
Insurance Supermarkets
IPTIQ
iLegacy Insurance
iLife and Health
Inside Response
Ins-leads.com
Insurance Care Direct
Insurance.com
InsuranceCareDirect
InsuranceLeads.com
InsuranceQuotes Inc.
insuranceQuotes.com
Insure Me Health
Insure.com
Insurify
Insurita
Insurzy
Integrate
Integrated Benefits
Integriant Ventures Insurance Services
IntelliQuote
iPlan Health Group
iPolicyQuote
Kaiser
Kaiser Foundation Health Plan
Kaiser Permanente
Kind Health
Kind Insurance Inc.
Ladder Life
LeadQual (LQ Digital)
Liberty First Health and Life
Liberty Health
Liberty Health Plan
Liberty Health Professionals
Liberty Health Solutions
Liberty Insurance Corp
Liberty Mutual
Liberty Mutual Fire Insurance
Liberty Mutual Insurance
Liberty Mutual Insurance Company
Liberty Mutual Nationwide
Liberty National
Liberty National Life Insurance Company
Liberty Northwest
Liberty Northwest Insurance
Life Quotes
LifeInsuranceMatchup
LifeLine Direct
Lifeline Direct Insurance
LifeQuotes
LifeSecure
Lincoln Benefit Life
Lincoln National
Madera Digital
Mass Mutual
Mass Nexus
MedGapDirect
Media Alpha Exchange
MediaAlpha
MediaMix
Medicare Group USA LLC
Medicare Health Benefits
Medicare Plan Finder
Medicare Providers
Medicare Solutions
Medicare Trusted Solutions
Medicare USA
MedicareAdvantage.com
Medicareplan.com
Medigap360
MEGA Life and Health
MEGA Life and Health Insurance
Metlife
MetLife Auto and Home
MetLife Insurance
Modern Health
Modern HealthLLC
Mayberry Advisors Insurance Services
MyHealthAngel.com
MyInsuranceExpert
MyMedsFree.com
MyRetiree Health
National Disability
National Family
National Family Assurance Group
National Family Assurance Group Inc.
National General Insurance
National General Insurance Co
National General Insurance Company
National Health
National Health Connect LLC
National Health Hub
National Health Hub LLC
National Health Insurance
National Health Plans
National Health Plans & Benefits Agents dba YourLowestQuote`}
            </Pre>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Pre>
              {`National Health Plans LLC
National Health Solutions
National Healthcare Advisors
National Income Life Insurance Company
National Indemnity
National Insurance
Nationwide
Nationwide Auto Protection
Nationwide General Insurance
Nationwide Health Advisors
Nationwide Insurance Company
Nationwide Mutual Fire Insurance
Nationwide Mutual Insurance
Nationwide Property and Casualty
New York Life
New York Life Insurance
NewFi
Newkirk Electric Associates Inc.
NewLeaf
Newport Solar
NewQuest
NexGen Construction
NexLevelDirect
Next Gen
Next Generation Alternative Energy
Next Level Benefits Incorporated
Next Step Energy
Next Step Living
Next Wave Marketing
Next Wave Marketing Strategies
NextGen
NextGen Leads
NextGen LeadsLLC
Nexus Corporation
Nexus Enterprise Solutions
Nexus Solar
NFC Mortgage CompanyLLC
NGIC
Northwestern Mutual
Northwestern Mutual Life
Northwestern Pacific Indemnity
Norvax
NorvaxLLC.
On Point LLC
Online Insurance Savers
Onviant
Onviant Insurance Agency Inc
Optimum HealthCare
Oscar
OSCAR Health
Oscar Health Insurance
Oxford
Oxford Health Plans
Oxford Insurance
Oxford Marketing Partners
Oxford Marketing PartnersLLC
Palo Media Group
Peak Advertising
Policy Scout
PolicyBind
PolicyFuel
PolicyScout
Porch.com
Progressive Insurance
Prudential
Prudential Insurance Co.
Q3M Insurance Solutions
Q3M Insurance Solutions LLC
Qualify Health
Qualify Life
Qualify Medicare
Qualilty Healthcare Solutions
Quality Health
Quality Health Organization
Quality Insurance Solutions
Quantum 3 Media
Quantum 3 Media LLC
Quick Health
Quick Medigap
QuickHealthInsurance.com
QuickInsured
QuinStreet
Quote Velocity
Quote Whiz LLC
Quote Wizard
Quotehound
Quotelab
QuoteLab Marketing Partners
QuoteLabLLC
QuoteManager LLC
QuoteStorm
QuoteWhiz
QuoteWhizLLC
QuoteWizard
Quote.com
Rank Media
Rank Media Agency
Reliance Insurance
Reliance National Indemnity
Reliance National Insurance
Reliant
Reliant LLC
Reliant USA LLC
ReliaQuote
Renaissance Health Services Corp.
ReviMedia
Secured Health
Secured Health and Life
SelectHealth
Selective Healthcare
SelectMyPolicy.com
SelectMyQuotes.com
SelectQuote
SelectQuote Insurance Services
SelectQuote Life
SelectQuote Senior
Senior Care Benefits
Senior Care USA
Senior Health Connect
Senior Health Connection
Senior Health Direct
Senior Healthcare Direct
Senior Market
Senior Market Advisors
Senior Market Partners
Senior Market Quotes
Senior Market Sales
Senior Plus Advisors
SeniorCare Benefits
SeniorCare USA
SeniorQuote Insurance Services
Simple Health
Simple Health Plans
Simple Health Plans Inc.
Simple Insurance
Simple Insurance Leads
Simple Insurance Services
Simplified Health
SolidQuote
SolidQuote LLC
Spectrum Direct
Spectrum Health
Spring Health Plans
Spring Insurance
Spring Insurance Solutions
Spring Venture Group
State Farm
State Farm Allstate Farmers Insurance
State Farm Fire and Cas
State Farm General
State Farm Indemnity
State Farm Insurance
Synergy Insurance Marketing
The General
The Hartford
The Hartford AARP
The Zebra
themedicareassistant.com
Tiburon Insurance
Tiburon Insurance Services
Tobias & Associates Inc
Today Medicare
Torchlight Technology Group
Total Insurance Brokers
Total Insurance Brokers INC
Total Insurance Brokers INC a.k.a. Health Coverage Fast
Towers Watson
Towers Watson dba OneExchange
TPG Direct
TradeMarc Global
Trademarc Global LLC
Traders Insurance
Transamerica
Tranzact
Tranzact Insurance Services
Tranzact Insurance Solutions
Travelers
Travelers Indemnity
Travelers Insurance
Travelers Insurance Company
Tricare
TruBridge
Trusted Consumer
Trusted Consumer Advisors
Trusted Health Quotes
TZ Insurance Solutions
TZ Insurance Solutions LLC
Underground Elephant
United American
United American Insurance Company
United American/Farm and Ranch
United Express Construction & Solar
United Fidelity Funding Corporation
United Health Care
United Health One
United Healthcare
United Healthcare Advisors
United HealthCare Services
United Insurance
United Medicare
United Medicare Advisors
United of Omaha
UnitedHealth Group
UnitedHealthcare
UnitedHealthcare/UnitedHealthOne
UnitedHealthOne
US Benefit Solutions
US Health Advisors
US Health Advisors LLC
US Health Benefits Group
US Health Group
US Health Group (Freedom Life)
US Insurance Advisors
US Insurance Online
US Medicare Group
US Web Leads
USA Benefits & Administrators
USA Benefits/Continental General
USA Dental Club
USA Health Options
USA Medical Card
USAA
USHealth Advisors
USHEALTH Group
USInsuranceOnline
Visiqua
Vital One Health
Vital One Health Plans Direct
Vitalone
Vitalone Health
VitalOne Health Plans
VitalOne Health Plans Direct
Wax Digital Solutions
Wellcare
WellCare Health Plans
YourHealthCareQuotes.com
            `}
            </Pre>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
