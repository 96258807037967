import { MouseEvent } from "react";
import { Grid, Hidden, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const CoverageTypeValidator = ({coverage_type}: any) => (
    ! coverage_type || coverage_type === '' ? 'Please answer the question.' : false
);

const CoverageType = ({ onChange }: ComponentProps) => {
    
    const { setValue, watch } = useFormContext();

    const value = watch('coverage_type');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {        
        setValue('coverage_type', value);        
        onChange('coverage_type', value, {
            autoNext: true
        });        
    };

    return (
        <Grid container sx={{ m: "auto", my: 5 }}>
            <Grid item xs={12} textAlign="center">
                <Hidden smDown>
                    <ToggleButtonGroup                        
                        value={value}
                        onChange={handleChange}
                        exclusive
                        color="primary"
                        size="large"
                        orientation="horizontal"
                    >
                        <ToggleButton value="Medicare Supplemental" sx={{ px: { xs: 4 }, borderRadius: 10 }}>Medicare Supplemental</ToggleButton>
                        <ToggleButton value="Medicare Advantage" sx={{ px: { xs: 4 }, borderRadius: 10 }}>Medicare Advantage</ToggleButton>
                        <ToggleButton value="Medicare Part D" sx={{ px: { xs: 4 }, borderRadius: 10 }}>Medicare Part D</ToggleButton>                        
                    </ToggleButtonGroup>                    
                </Hidden>
                <Hidden smUp>
                    <ToggleButtonGroup                        
                        value={value}
                        onChange={handleChange}
                        exclusive
                        color="primary"
                        size="large"
                        orientation="vertical"
                    >
                        <ToggleButton value="Medicare Supplemental" sx={{ px: { xs: 4 }, borderRadius: 10 }}>Medicare Supplemental</ToggleButton>
                        <ToggleButton value="Medicare Advantage" sx={{ px: { xs: 4 }, borderRadius: 10 }}>Medicare Advantage</ToggleButton>
                        <ToggleButton value="Medicare Part D" sx={{ px: { xs: 4 }, borderRadius: 10 }}>Medicare Part D</ToggleButton>                        
                    </ToggleButtonGroup>         
                </Hidden>
            </Grid>
        </Grid>
    )
};

export default CoverageType;