import { Suspense, lazy } from "react";
import { Typography, Box, Slide, Paper } from "@mui/material";
import { Loading } from "../ContentLoading";

export interface ComponentProps {
    onChange: Function;        
    submitResults?: any;     
}

export interface QuestionProps {
    title: string;
    description: string;
    component: string;
    skip_conditions: any;
}

interface SlidesProps {
    questions: Array<QuestionProps>;
    active: number;
    direction: string;
    onChange ?: Function;
    setValidator ?: any;
    submitResults ?: any;
}

const getForm = (name: string, props: any) => {
    const Form = lazy(() => import(`./${name}`));

    return (<Form {...props} />);
}

const Slides = ({ questions, active, direction, ...props }: SlidesProps|any) => (
    <Box>
        {questions.map(({ title, description, component }: QuestionProps, index: number) => (
            <Slide
                key={index}
                direction={(index === active ? direction : (direction === "right" ? "left" : "right"))}
                in={index === active}
                style={{ display: (index != active ? "none" : "block") }}
                mountOnEnter
                unmountOnExit
                >
                    <Box>
                        <Suspense fallback={<Loading />}>
                            <Typography sx={{ mt: 2, mb: 1 }} align="center" variant="h5">{title}</Typography>
                            <Typography align="center" variant="subtitle2">{description}</Typography>                        
                            {getForm(component, props)}
                        </Suspense>                                
                    </Box>

            </Slide>)
        )}
    </Box >
);

export default Slides;