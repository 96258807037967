import React, { useState, useEffect } from "react";
import {Container, Paper, Typography, Grid, Box} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useCookies } from 'react-cookie';
import { randomPingPostOption } from '../../utils/client'
import { v4 as uuidv4 } from 'uuid';

import { getToken, getLocation, postCheck, postRegister, patchSignup, getSurveys } from '../../utils/HealthSurveyClient';
import { getZip } from '../../utils/ZippopotamusClient';

import { FormProvider, useForm } from "react-hook-form";
import SurveyForm from "../../components/Survey/SurveyForm";
import fbq from "../../utils/Pixel";

const Span = styled("span")(({ theme }) => ({
    fontWeight: 800
}));

const HeroBanner = () => {          
    const [cookies, setCookie, removeCookie] = useCookies<any>(['S']);
    const step = cookies?.S?.step || 0; 

    const [stepSlides, setStepSlides] = useState<any>([]);    
    const [leadAddress, setLeadAddress] = useState<any>({});
    const [data, setData] = useState<any>({});

    const methods = useForm();    
    
    const saveLocation = (address: any) => {        
        setLeadAddress(address);        
        let survey = {...(cookies?.S?.survey || data?.survey || {})};        

        if (! survey?.address_city || survey?.address_city === '')
            survey = {...survey, address_city: address.address_city};
        
        if (! survey.address_state || survey.address_state === '')
            survey = {...survey, address_state: address.address_state};

        if (! survey.address_zip || survey.address_zip === '')
            survey = {...survey, address_zip: address.address_zip};

        setCookie('S', {step, survey}, { path: '/'}); 
        
        setData({ ...data, survey });
    }

    const validateLocation = async (zip: string) => {        
        if (zip) {
            await getZip(zip).then((response: any) => {
                    if (response) {
                        const address = response.data.places[0];                        
                        saveLocation({
                            address_state: address['state abbreviation'],
                            address_zip: zip,
                            address_city: address['place name'],
                        });
                    }
                });
        } else {
            await getLocation().then((response: any) => {                                
                saveLocation(response.data);                
            });
        }
    }

    const startSurvey = (data: any) => {
        if (data.token) {
            localStorage.setItem(data.token.token_type, data.token.access_token);           
        }

        if (data.survey) {
            setCookie('S', {step: 0, survey: data.survey}, {path: "/"});            
        } 
        
        setData(data);
    }

    /**
     * Check the requirements before to start the survey.
     */
     useEffect(() => {         

        const id = process.env.REACT_APP_API_SURVEY_ID;
        const site_id = process.env.REACT_APP_API_SITE_ID;

        getSurveys({id, site_id}).then((response: any) => {                        
            setStepSlides(response?.data?.questions ?? []);
            const survey_id = response?.data?.survey_id;            

            postCheck({
                has_survey: cookies.S?.survey ? true : false,
                has_token: getToken() ? true : false,            
                uuid: cookies.UUID || null,
            }).then((response: any) => {            
                if (response.status === 200) {
                    let data = response.data;                
                    if (! data.is_valid_uuid) {
                        const uuid = uuidv4();
                        postRegister({ uuid, survey_id }).then((response: any) => {
                            if (response.status === 201) {                            
                                setCookie('UUID', uuid, { path: '/', maxAge: 2592000 }); // 30 days
                                startSurvey(response.data);
                            }
                        });
                    }                    
                    else 
                        startSurvey(data);
                }
            });
            
            if (fbq) fbq('track', 'ViewContent');
        }); 
        
        const QueryParams = new URLSearchParams(window.location.search);
        const zip = QueryParams.get('zip');
        validateLocation(zip ?? "");
        randomPingPostOption();
        
        const sub_1 = QueryParams.get('sub_1');
        const cid = QueryParams.get('cid');        
        if (sub_1 || cid)
            setCookie('subs', { "sub_1": sub_1, "sub_3": cid, }, { path: '/' });
    }, []);
    
    return (
        <Paper sx={{
                position: "relative",
                color: "#fff",
                mb: 4,
                background: "linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url(images/bg.jpg)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundAttachment: "fixed",
                borderRadius: 0,
                borderBottomLeftRadius: 120,
                borderBottomRightRadius: 120,
            }}>
            <Container maxWidth="xl">               
                <img style={{ display: "none" }} src="images/background.png" alt="Senior HealthCare USA"/>
                <Box sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: "rgba(0,0,0,.3)",
                        borderBottomLeftRadius: 120,
                        borderBottomRightRadius: 120,
                    }}/>
                <Grid container sx={{ py: 4 }}>
                    <Grid item lg={6}>
                        <Box sx={{ position: "relative", p: { xs: 1, sm: 3, md: 6 }}}>
                            <Typography
                                variant="h3"
                                color="inherit"
                                gutterBottom
                                sx={{
                                    fontSize: { xs: 28, sm: "3rem" },
                                    textShadow: "0 2px 5px #000",
                                    textAlign: { xs: "center", sm: "left" }
                                }}>
                                Discover Your Medicare Coverage Options in {leadAddress?.address_city ?? "US"}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="inherit"
                                paragraph
                                sx={{
                                    fontSize: { xs: 18, sm: 26 },
                                    textShadow: "0 2px 5px #000",
                                    textAlign: { xs: "center", sm: "left" }
                                }}>
                                Shopping for insurance can be confusing and frustrating; we're here to help you compare your options in <Span>{leadAddress?.state ?? "US"}.</Span>
                            </Typography>
                            <Typography
                                variant="body1"
                                color="inherit"
                                paragraph
                                sx={{
                                    fontSize: { xs: 18, sm: 26 },
                                    textShadow: "0 2px 5px #000",
                                    textAlign: { xs: "center", sm: "left" }
                                }}>
                                There's even a Medicare Advantage plan with ZERO monthly premiums!*
                            </Typography>
                            {/* <Typography variant="body1" color="inherit" paragraph sx={{ fontSize: 26 }}>
                                You may be eligible for a Medicare Advantage plan with a $0
                                monthly premium*
                            </Typography> */}
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box
                            sx={{
                                position: "relative",
                                p: { xs: 1, sm: 3, md: 6 },
                                // pr: { md: 0 },
                            }}>
                            <Paper>
                                <Box p={4} textAlign={"center"}>
                                    <Typography
                                        variant="h5"
                                        color="inherit"
                                        mb={2}
                                        fontWeight={600}
                                    >
                                        Medicare Quotes to Compare
                                    </Typography>
                                    <Typography variant="body1" color="inherit">
                                        Simply answer the next few questions and click NEXT each time to discover your Medicare possibilities!
                                    </Typography>
                                    <FormProvider {...methods}>                                        
                                        <SurveyForm LeadAddress={leadAddress} StepSlides={stepSlides} Signup={data} />
                                    </FormProvider>                                    
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}

export default HeroBanner;
