import React from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Hidden,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VerticalAlignMiddle from "../../components/VerticalAlignMiddle";

export default function ContentOne() {
  const cards = [
    {
      icon: "images/card1.png",
      title: "We can help you find a plan, no matter what your budget",
      description:
        "Licensed and knowledgeable agents will help you analyze the pros and cons of your Medicare options.A licensed insurance agent can show you plan information so you can clearly compare the cost and coverage of your Medicare options.",
    },
    {
      icon: "images/card2.png",
      title: "Analyzing Your Options",
      description:
        "There are agents available now that will be happy to help you contrast the different Medicare options to find the perfect plan for you!",
    },
    {
      icon: "images/card3.png",
      title:
        "Discover the best Medicare insurance options for you and your lifestyle",
      description:
        "Many seniors have discovered that their current Medicare plan doesn’t meet their needs they way they’d hoped. Here’s a way to make sure that doesn’t happen to you!",
    },
  ];

  const Image = styled("img")(({ theme }) => ({
    borderRadius: 10,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      display: "block",
    },
  }));

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item md={12}>
          <Box
            sx={{
              p: { xs: 3, md: 6 },
              textAlign: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h3"
              color="inherit"
              gutterBottom
            >
              Our Process is Fast & Easy!
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              paragraph
              sx={{ fontSize: 24 }}
            >
              We take the hassle out of shopping for insurance. Reviews are
              fast, and there is no obligation to enroll.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          sx={{
            px: { xs: 3, md: 6 },
          }}
        >
          {cards.map(({ icon, title, description }) => (
            <Grid item key={title} sm={12} md={4}>
              <Card
                sx={{
                  my: { xs: 3 },
                  transition: "all 0.3s ease-in-out",
                  //   height: "100%",
                  "&:hover": {
                    // background: "#f00",
                    transform: "scale(1.1)",
                  },
                }}
              >
                {/* <CardActionArea> */}
                <CardMedia
                  component="img"
                  image={icon}
                  alt={title}
                  sx={{
                    // borderRadius: 100,
                    height: 100,
                    width: 100,
                    m: "30px auto",
                  }}
                />
                <CardContent
                  sx={{
                    textAlign: "center",
                    height: { md: 450, lg: 300 },
                  }}
                >
                  <Typography gutterBottom variant="h6" component="div">
                    {title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {description}
                  </Typography>
                </CardContent>
                {/* </CardActionArea> */}
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          sx={{
            px: { xs: 3, md: 6 },
            my: { xs: 3, md: 6 },
          }}
        >
          <Grid item xs={12} md={5}>
            <VerticalAlignMiddle
              component={
                <Image
                  src={`images/content_image_1.png`}
                  srcSet={`images/content_image_1.png`}
                  alt="Senior HealthCare USA"
                  loading="lazy"
                  style={{ width: "100%" }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <VerticalAlignMiddle
              component={
                <Box
                  sx={{
                    mt: { xs: 3, sm: 3, md: 0 },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    color="text"
                    component="div"
                  >
                    How to get the most out of your Medicare plan
                  </Typography>
                  <Typography variant="body1" color="text.secondary" mt={2}>
                    We will help you compare Original Medicare plans versus
                    private insurance plans with Medicare-approved options. The
                    goal is to find you your best possible Medicare plan based
                    on your income and lifestyle.
                  </Typography>
                  <Typography variant="h6" color="text" mt={2} fontWeight="800">
                    WE DO ALL THE WORK, SO YOU DON’T HAVE TO!
                  </Typography>
                </Box>
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          sx={{
            px: { xs: 3, md: 6 },
            my: { xs: 3, md: 6 },
          }}
        >
          <Hidden mdUp>
            <Grid item xs={12} md={5}>
              <VerticalAlignMiddle
                component={
                  <Image
                    src={`images/content_image_2.png`}
                    srcSet={`images/content_image_2.png`}
                    alt="Senior HealthCare USA"
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                }
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={7}>
            <VerticalAlignMiddle
              component={
                <Box
                  sx={{
                    mt: { xs: 3, sm: 3, md: 0 },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    color="text"
                    component="div"
                  >
                    What if there’s something better for you available?
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    That’s where the highly trained insurance personnel excel.
                    They will help you navigate the wide range of Medicare
                    options to find the perfect plan for your unique lifestyle.
                  </Typography>
                  <Typography variant="h6" color="text" mt={2} fontWeight="800">
                    Your quote is just a few clicks away or a phone call away!
                  </Typography>
                </Box>
              }
            />
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={5}>
              <VerticalAlignMiddle
                component={
                  <Image
                    src={`images/content_image_2.png`}
                    srcSet={`images/content_image_2.png`}
                    alt="Senior HealthCare USA"
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                }
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}
