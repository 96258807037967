import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Container, Grid, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { data } from "jquery";
// import { Link } from "react-router-dom";

const styles = (theme) => ({
  toolbarRoot: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
  },
});

const Footer = (props) => {
  const { classes, handleToggleDrawer } = props;
  const Image = styled("img")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      display: "block",
    },
  }));

  const today = new Date();

  return (
    <Paper
      sx={{
        position: "relative",
        // color: "#fff",
        backgroundColor: "#262626",

        color: "#fff",
        mb: 4,
        borderRadius: 0,
        borderTopLeftRadius: 120,
        borderTopRightRadius: 120,
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ p: { xs: 3, md: 6 } }}>
          <Typography
            variant="body2"
            align="justify"
            sx={{ fontSize: 14, lineHeight: 1.6 }}
          >
            <strong> Disclaimer: </strong> The administrator of this website is
            not an insurance broker or insurance company and does not sanction
            any specific broker or insurance provider, nor does the
            administrator make any insurance decisions. The website’s function
            is to provide the brokers with the information you contribute via
            the website, and we do not solicit insurance providers. Contributing
            your information on this website is not a guarantee you will be
            provided insurance; that will be between the insurance providers and
            you. Any quoted rates/savings advertised are not necessarily
            provided by all insurance companies or their advertisers. Your
            quoted rates or savings may vary based on your personal factors
            which varies with the insurance policy type. If you have any
            questions regarding the insurance policy secured through our
            website, please contact the broker you worked with or the insurance
            company directly. Being a resident of some states may make you
            ineligible for some insurances or may come with very high premiums.
            You have no obligations to consult our website services to begin
            contact, nor must you apply for any products or brokerage. Our
            website cannot guarantee to provide you all available insurance
            options. Due diligence, on your part, to research other websites is
            prudent. Any copyrights or trademarks are the ownership of the
            respective insurance representatives. *Outcomes from using our
            website will vary due to personal information provided. Availability
            of the $0 premiums is based on location. All rates and plans are
            subsequent to change.
          </Typography>
          <Typography
            variant="body2"
            align="justify"
            mt={4}
            pl={4}
            sx={{ fontSize: 14 }}
          >
            * Results of using the service may vary according to individual
            information provided. $0 premium is based on location and
            availability. Plans and rates may vary.
          </Typography>
        </Box>
        <Grid
          container
          sx={{
            px: { xs: 3, md: 6 },
            pb: 6,
            textAlign: { xs: "center" },
            fontSize: 14,
          }}
        >
          <Grid item md={6} sm={12} xs={12}>
            <Typography
              variant="body2"
              sx={{
                textAlign: { sm: "center", md: "left" },
                fontSize: 14,
              }}
            >
              <Link href="/privacy-policy" underline="none">
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link href="/terms-of-use" underline="none">
                Terms of Use
              </Link>{" "}
              |{" "}
              <Link href="/ccpa" underline="none">
                CCPA
              </Link>{" "}
              {/* |{" "}
              <Link href="/partners" underline="none">
                Partners
              </Link> */}
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography
              variant="body2"
              sx={{
                textAlign: { sm: "center", md: "right" },
                fontSize: 14,
              }}
            >
              Copyright © {today.getFullYear()}
            </Typography>
          </Grid>
        </Grid>
        {/* </Box> */}
      </Container>
    </Paper>
  );
};

export default withStyles(styles)(Footer);
