import { useState } from "react";
import { Grid, FormControl, InputLabel, MenuItem, Select, useMediaQuery, useTheme, SelectChangeEvent } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const DateOfBirthValidator = ({birth_month, birth_day, birth_year}: any) => {
    if (! birth_month || birth_month === '')
        return 'Please select your Birth Month.';
    
    if (! birth_day || birth_day === '')
        return 'Please select your Birth Day.';

    if (! birth_year || birth_year === '')
        return 'Please select your Birth Year.';

    return false;
};

const DateOfBirth = ({ onChange }: ComponentProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const today:any = new Date();
    const MAX_LEGAL_AGE = 18;    
    const MAX_AGE = 75; 
    const getListDaysOfTheMonth = (month: number) => {
        const now = new Date(today.getFullYear() - MAX_LEGAL_AGE, month, 0);                
        return Array(now.getDate()).fill(1).map((value, n) => value + n);
    };

    const setAge = (value: number) => {
        const month = getValues('birth_month') || today.getMonth();
        const day = getValues('birth_day') || today.getDay()
        const age = Math.floor((today - new Date(`${value}-${month}-${day}`).getTime()) / 3.15576e+10)

        setValue('age', age);
        onChange('age', age);
    }    

    const years = Array(MAX_AGE).fill(today.getFullYear()-MAX_LEGAL_AGE).map((value, n) => value - n);     
    const [days, setDays] = useState<Array<number>>(getListDaysOfTheMonth(today.getMonth() + 1));
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]; 

    const { setValue, getValues, watch } = useFormContext();
    
    const birth_year = watch('birth_year');
    const birth_day = watch('birth_day');
    const birth_month = watch('birth_month');

    const handleChange = (event: SelectChangeEvent) => {
        const name = event.target.name;
        const value = parseInt(event.target.value);

        switch(name) {
            case 'birth_month':                
                const days = getListDaysOfTheMonth(value < 1 ? today.getMonth() : value);
                if (parseInt(birth_day) > days[days.length-1])           
                    setValue('birth_day', '');

                setDays(days);                
              break;
            case 'birth_year': 
                if (value) setAge(value);
              break;                        
        }

        setValue(name, event.target.value)
        onChange(name, event.target.value);
    }
   
    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "500px" }} spacing={isSmall ? 0 : 2}>
            <Grid item md={4} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <FormControl fullWidth>
                    <InputLabel id="birth_month">Month</InputLabel>
                    <Select
                        label="Month"
                        name="birth_month"
                        value={birth_month || 0}
                        onChange={handleChange}
                    >
                        <MenuItem value={0}>MM</MenuItem>
                        {months.map((value, index) => {
                            const key = index + 1;                            
                            return (<MenuItem key={key} value={key}>{value}</MenuItem>);
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <FormControl fullWidth>
                    <InputLabel id="birth_day">Day</InputLabel>
                    <Select
                        label="Day"
                        name="birth_day"
                        value={birth_day || ''}                        
                        onChange={handleChange}
                    >
                        <MenuItem value=''>DD</MenuItem>
                        {days.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="birth_year">Year</InputLabel>
                    <Select
                        label="Year"
                        name="birth_year"
                        value={birth_year || ''}                        
                        onChange={handleChange}
                    >
                        <MenuItem value=''>YYYY</MenuItem>
                        {years.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
};

export default DateOfBirth;