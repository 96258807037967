import React from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  List,
  ListItemText,
  Link,
} from "@mui/material";
import CaliforniaConsumerAct from "./CaliforniaConsumerAct";

export default function TermsOfUse() {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          p: { xs: 3, md: 6 },
        }}
      >
        <Typography variant="h3">Terms of Use</Typography>
        <Divider sx={{ my: 3 }} />
        <Typography variant="body1">
          Below you will find our terms and conditions, which oversee the
          services optioned through Global Digital Revenue, LLC (from now on
          referred to as “the “Site”). By utilizing the Site, which includes:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>• Requesting insurance quotes</ListItemText>
          <ListItemText>• Calling our toll-free number</ListItemText>
          <ListItemText>
            • Requesting us to contact you by telephone
          </ListItemText>
          <ListItemText>
            • Signing up for email news and information
          </ListItemText>
          <ListItemText>
            • Any other means that may become available
          </ListItemText>
        </List>
        <Typography variant="body1" paragraph>
          you are accepting and agreeing to the Terms of Use. Should you not
          agree to our Terms of Use, you will be prevented from utilizing our
          services or accessing the Site. Below, please note that the terms “we”
          or “us” refer to Global Digital Revenue, LLC, it’s associates and
          affiliates.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Services
        </Typography>
        <Typography variant="body1" paragraph>
          The Site exists to provide consumers with the opportunity to receive
          quotes from various insurance carriers and other service providers who
          have paid a fee to be a part of the Site. After you provide us with
          information regarding your location, health concerns, etc., we try to
          match you with the most appropriate, most affordable coverage and
          provide you links to allow you to contact a representative quickly and
          easily. As such, we are unable to guarantee you will be approved, as
          that is up to the Insurance Provider. We are not responsible for
          anything the Insurance Providers say or do. Any policy you receive
          through our affiliates has its own Terms and Conditions and you should
          be sure to review them before signing.
        </Typography>
        <Typography variant="body1" paragraph>
          The Site represents, Global Digital Revenue, LLC which is a marketing
          firm bringing together consumers and knowledgeable Insurance Agents in
          your area. These agents can give you a list of options for your
          Medicare needs, considering where you live, what health problems you
          may have, etc. We are not affiliated with the government or any
          Federal agency.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Accessing Our Site
        </Typography>
        <Typography variant="body1" paragraph>
          Our site is only available to persons in the USA that are at least 18
          years of age. We retain the right, at our sole discretion, to change,
          suspend, terminate, or delete any aspect of the Site at any time.
          Additionally, we may restrict access to part of or the entirety of the
          Site without penalty or notice.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Your User Data
        </Typography>
        <Typography variant="body1" paragraph>
          While using the Site, you will be asked to provide information or
          materials to use (which will now be known as “User Data”). This data
          includes, for example, the information given to us regarding your
          location, age, etc. We recommend you read our{" "}
          <Link href="/privacy-policy" underline="none">
            Privacy Policy
          </Link>{" "}
          before providing any User Data. By participating, you are
          acknowledging that you are solely responsible for the correctness of
          your submitted information. We are not to be held responsible for
          maintaining your User Data and we have the right to delete or destroy
          your User Data at our discretion. We maintain the right to refuse to
          post or remove your User Data if it is inappropriate, unacceptable,
          undesirable, unlawful, or if it violates our Terms of Use.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Consent to Contact
        </Typography>
        <Typography variant="body1" paragraph>
          The act of providing your contact information via the Site
          acknowledges your agreement to receive communications from Senior
          Health Care USA or from a third-party affiliate. This agreement grants
          us the right to send you newsletters or additional promotions or
          offers. Occasionally, we employ an automated dialing system that
          contains a pre-recorded message and/or an interactive response system,
          along with SMS messaging. Should you no longer wish to get these
          messages or email, please contact Customer Care at
          hello@seniorhealthcareusa.com. We additionally provide an
          “Unsubscribe” link in the footer of every webpage.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Exclusive Rights of Ownership
        </Typography>
        <Typography variant="body1" paragraph>
          Any necessary software used within the Site contains confidential and
          proprietary information that is covered by intellectual property laws
          in all applicable jurisdictions. By using the Site, you are
          acknowledging that the materials and information within the Site are
          protected by trademarks, service marks, copyrights, patents, or other
          proprietary rights laws and rights. Except as specifically permitted
          by applicable law or sanctioned by us, you agree not to sell, modify,
          transmit, distribute, publicly perform, broadcast, or create
          derivative works based on the Site, in whole or part. Your commercial
          use of the Site, or any portion thereof, is prohibited by law.
        </Typography>
        <Typography variant="body1">
          Global Digital Revenue LLC authorizes you a non-exclusive and
          non-transferable license to use the Site on any device that connects
          to the Internet, provided you do not (nor do you allow a third party
          to):
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>• Copy</ListItemText>
          <ListItemText>• Reproduce</ListItemText>
          <ListItemText>• Distribute</ListItemText>
          <ListItemText>• Reverse engineer</ListItemText>
          <ListItemText>• Exploit</ListItemText>
        </List>
        <Typography variant="body1" paragraph>
          any software codes, data, content, or materials on the Site. You also
          agree not to adjust the Site in any way or form, nor to use any
          modified variations of the Site for any purpose. We hereby do not
          grant any license or other authorization to any persons to use our
          trademarks, service marks, trade names, and logos, as well as of those
          of our Affiliates, without our express separate written agreement.
          Third-party marks are the hallmarks of their respective owners.
        </Typography>
        <Typography variant="body1" paragraph>
          All comments, questions, suggestions, or data submitted to us via the
          Site becomes our sole property. We retail all rights with such
          materials and are given unrestricted rights to publish, use, and
          advertise such information for any reason, with no attribution or
          compensation.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Disclaimer of Warranties
        </Typography>
        <Typography variant="body1" paragraph>
          The services and information on the Site are provided “as is” and are
          to be considered for informational purposes exclusively. We do not
          guarantee or warranty that the Site will be applicable to your needs,
          nor that the Site is timely, reliable, complete, or free from
          inaccuracies, errors, or typographical mistakes. We disavow all
          warranties, implied or expressed, including warranties of fitness or
          merchantability for any particular purpose or non-infringement of the
          other’s right. We cannot claim that the Site will be free or viruses,
          worms or other destructive or harmful software code.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          IN NO EVENT SHALL Global Digital Revenue LLC, ANY OF OUR DIRECTORS,
          OFFICERS, EMPLOYEES, AGENTS, AFFILIATES, OR CONTENT OR SERVICE
          PROVIDERS (COLLECTIVELY, THE “PROTECTED ENTITIES”) BE LIABLE FOR ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR
          PUNITIVE DAMAGES ARISING FROM, OR DIRECTLY OR INDIRECTLY RELATED TO,
          THE USE OF, OR THE INABILITY TO USE, THE SITE AND THE SERVICES OFFERED
          HEREBY, OR THE CONTENT, MATERIALS AND FUNCTIONS RELATED THERETO,
          INCLUDING, WITHOUT LIMITATION, LOSS OF REVENUE, EVEN IF ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL OUR TOTAL LIABILITY TO
          YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN
          CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, OR
          OTHERWISE) EXCEED FIVE DOLLARS ($5.00). THE ABOVE LIMITATION MAY NOT
          APPLY IN ALL JURISDICTIONS OR TO ALL USERS. IF YOU ARE NOT IN
          AGREEMENT WITH THE FOREGOING, YOUR SOLE REMEDY IS TO TERMINATE THIS
          AGREEMENT AND DISCONTINUE USE OF THE SITE.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Site Links
        </Typography>
        <Typography variant="body1" paragraph>
          All links contained with the Site are for informational purposes
          exclusively and consist of advertising from and to other sites. We
          bear no responsibility for the availability or accuracy of the
          information provided by these links. Showing the availability of these
          links does not infer endorsement of, or any association with, such
          sites, along with the products, content, advertising, or other
          materials presented at these sites. You agree and acknowledge that we
          are not liable or responsible, directly or indirectly, for any loss or
          damage alleged or caused by or because of connection with your
          reliance or use of any goods, content, or services available on such
          sites. You also acknowledge that we do not have control over, and
          assume no responsibility for, any practices, privacy policies, or
          content of any third-party sites contained within the Site, or to
          which the Site is linked, or on which Site Content is posted. We
          charge no fee for users of our site. We are financially compensated by
          the participating Insurance Companies or other companies who choose to
          advertise on the Site.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Updates
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to change the Terms of Use at any time, with such
          changes becoming effective upon posting to the Site. Your use of the
          Site, following these modifications, signifies your acknowledgement
          and acceptive of said modifications.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Arbitration Agreement
        </Typography>
        <Typography variant="body1" paragraph>
          Any dispute or claim relating in any way to your use of this website,
          including any related calls texts or other communications, will be
          resolved by binding arbitration, rather than in court, except that you
          may assert claims in small claims court if your claims qualify. This
          includes claims against our clients, vendors, and Marketing Partners,
          which are third party beneficiaries of this arbitration agreement. The
          Federal Arbitration Act and federal arbitration law apply to this
          agreement.
        </Typography>
        <Typography variant="body1" paragraph>
          There is no judge or jury in arbitration, and court review of an
          arbitration award is limited. However, an arbitrator can award on an
          individual basis the same damages and relief as a court (including
          injunctive and declaratory relief or statutory damages), and must
          follow the terms of these terms as a court would.
        </Typography>
        <Typography variant="body1" paragraph>
          The arbitration may be conducted by the American Arbitration
          Association (AAA) under its rules, including the AAA's Supplementary
          Procedures for Consumer-Related Disputes. The AAA's rules are
          available at www.adr.org or by calling 1-800-778-7879. You may choose
          to have the arbitration conducted by telephone, based on written
          submissions, or in person in the county where you live or at another
          mutually agreed location.
        </Typography>
        <Typography variant="body1" paragraph>
          We each agree that any dispute resolution proceedings will be
          conducted only on an individual basis and not in a class, consolidated
          or representative action. If for any reason a claim proceeds in court
          rather than in arbitration we each waive any right to a jury trial. We
          also both agree that you or we may bring suit in court to enjoin
          infringement or other misuse of intellectual property rights.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          Email: <a href="mailto:hello@seniorhealthcareusa.com">hello@seniorhealthcareusa.com</a><br />          
          Phone: (877) 920-4527
        </Typography>
      </Box>
    </Container>
  );
}
