import { useState, useEffect, useRef } from "react";
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import {
    Typography, 
    Button,
    Box,
    Link,
    useTheme
} from "@mui/material";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';
import LoadingButton from '@mui/lab/LoadingButton';
import { Loading } from "./ContentLoading";
import $ from 'jquery';

import Validator from '../../utils/Validator';
import ProgressBar from "./ProgressBar";
import Slides from "./questions";
import { useFormContext } from "react-hook-form";
import { getToken, patchSignup, putSignup } from '../../utils/HealthSurveyClient';
import useSnackbar from "./SnackBar";
import fbq from "../../utils/Pixel";

const SurveyForm = ({LeadAddress, StepSlides, Signup}: any) => {    
    const theme = useTheme();
    const history = useHistory();    
    const { reset, getValues, handleSubmit } = useFormContext();
    const { Snackbar, open: openSnackbar, close: closeSnackbar } = useSnackbar();
    const leadTokenRef = useRef<any>();
    
    const [loading, setLoading] = useState<boolean>(false);        
    const [direction, setDirection] = useState<string>("left");
    const [submitResults, setSubmitResults] = useState<any>({ show_full: true });
    
    const [cookies, setCookie, removeCookie] = useCookies<any>(['S']);             
    const [step, setStep] = useState(cookies?.S?.step || 0);
              
    const totalSteps = () => (StepSlides.length);        
    const isLastStep = () => (step === totalSteps() - 1);
    const skipping = (targetStep: number, isNext: boolean) => {
        const params: any = getValues();

        let i = targetStep;
        do {
            const question = StepSlides[i];            
            const skipConditions: any = question?.skip_conditions ?? false;                    
            let isSkipped = false;
            if (skipConditions) {                                
                for (var key in skipConditions) {                                                            
                    if (typeof skipConditions[key] === 'object')
                    {
                        const [operator, threshold] = skipConditions[key];                        
                        switch (operator) {
                            case 'gt': isSkipped = (params[key] > threshold);
                                break;
                            case 'ge': isSkipped = (params[key] >= threshold);
                                break;
                            case 'lt': isSkipped = (params[key] < threshold);
                                break;
                            case 'le': isSkipped = (params[key] <= threshold);
                                break;                        
                        }
                    }
                    else isSkipped = (params[key] === skipConditions[key]);                        
                    if (isSkipped) {
                        isNext ? ++targetStep : --targetStep;
                        break;
                    }                        
                } 
            }

            if (! isSkipped) break; 

            isNext ? ++i : --i;
        } 
        while (isNext ? i < totalSteps() : i > 0)        
        return targetStep;
    } 
    
    const validate = () => {
        const slide = StepSlides[step];
        const validator = Validator(slide.component);
        
        if (validator) {
            const error = validator.validate(getValues(), submitResults ? true : false);
            if (error) return openSnackbar('error', error); 
        }
        
        return true;
    }
    
    /**
     * Handlers
     */
     const handleFormChange = (key: string, value: any, options: any) => {                
        let id = getValues('id');
        if (! id || id === '')
            id = Signup?.survey?.id;
        
        patchSignup({
            id,
            [key]: value
        });
        
        if (! ['address', 'address_city', 'address_state', 'address_zip',
               'birth_day', 'birth_month', 'birth_year', 'age',
               'firstname', 'lastname', 'email', 'phone'].includes(key))
            closeSnackbar();
        
        if (options?.autoNext) 
            handleNext();
        
        // saveSignup(data);        
    }

    const handleNext = () => {
        if (! validate()) return false;        
        const newStep = skipping(step + 1, true);
        setDirection("left");
        setStep(newStep);
        setCookie('S', {step: newStep, survey: getValues()}, {path: '/'});        
    };

    const handleBack = () => {                
        const newStep = skipping(step - 1, false);        
        setDirection("right");
        setStep(newStep);
        setCookie('S', {step: newStep, survey: getValues()}, {path: '/'});
        closeSnackbar();
    };

    const onSubmit = async (survey: any) => {
        if (! validate()) return false;
        setLoading(true);
        
        // removeCookie('S', { path: '/' });                
        removeCookie('offerReload', { path: '/' })
        const pingPostType = localStorage.getItem('pingPostType') === "submitOption" ? 1 : 0;
        const trustedform = $("#xxTrustedFormToken_0").val() ?? '';
        const leadid_token = leadTokenRef?.current?.value || $("#leadid_token").val();
        setCookie('leadid_id', leadid_token);  
        
        if (! survey.uuid)
            survey.uuid = cookies?.UUID;

        if (! survey.id)        
            survey.id = Signup?.survey?.id;
        
        //history.push(`/offers?state=${stepsData.survey.address_state}&zip=${stepsData.survey.address_zip}&senior=0`)
        const sub_1 = cookies?.subs?.sub_1 ?? '';
        const sub_3 = cookies?.subs?.sub_3 ?? '';
        const query = (sub_1 !== '' ? `&sub_1=${sub_1}` : '') + (sub_3 !== '' ? `&cid=${sub_3}` : '');
        if (getToken()) {
            await putSignup({ 
                ...survey,                                                
                is_submitted: 1,
                trusted_form: trustedform,
                lead_id: leadid_token,
                pingpost: pingPostType,                
                sub_1,
                sub_3,
            }).then((response: any) => {
                const data = response.data;
                if (fbq && data.leads) {
                    data.leads.map((it: any) => fbq('track', 'Lead', {
                        content_name: it.lead,
                        value: it.bid,
                        currency: 'USD'                        
                    }));
                }
                                
                if (! data.errors) {
                    setCookie('S', {step: 0, survey: survey}, { path: '/' });

                    // history.push(`/offers?state=${survey.address_state}&zip=${survey.address_zip}&senior=0${query}&stage=${data.stage}`);
                    history.push(`/offers?state=${survey.address_state}&zip=${survey.address_zip}${query}&stage=${data.stage}`);
                }                 
            }).catch(({response}: any) => {
                openSnackbar('error', 'Please answer the question correctly.');
                setSubmitResults(response.data);
                setLoading(false);    
            });
        }
    };

    /**     
     * update cookie if submit fails
     */
     useEffect(() => {        
        setCookie('S', {step, survey: getValues()}, { path: '/' });                      
    }, [submitResults]);

    /**
     * on mount reset the react form
     */
    useEffect(() => {        
        reset(cookies?.S?.survey || {});        
    }, []);

    return (totalSteps() < 1 ? <Loading /> :
        <Box py={2}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ProgressBar steps={totalSteps()} step={step} />                
                <input type="hidden" id="leadid_token" name="universal_leadid" ref={leadTokenRef} />                
                <Slides 
                    questions={StepSlides} 
                    active={step} 
                    direction={direction}                     
                    onChange={handleFormChange}                                     
                    submitResults={submitResults} />
                
                <Box sx={{ display: (isLastStep() ? "none" : "block") }}>
                    <Button
                        type="button"
                        size="small"
                        onClick={handleNext}
                        variant="contained"
                        disabled={isLastStep()}
                        hidden={isLastStep()}
                        sx={{ py: 2, px: 6, mb: 2, borderRadius: 20 }}>
                        Next
                    </Button>
                </Box>
                {/* <Box textAlign="center"> */}
                <Box textAlign="center" sx={{ display: (isLastStep() ? "none" : "block") }}>
                    {/* <Grid item xs={12} textAlign="center"> */}
                    <Button
                        type="button"
                        size="small"
                        onClick={handleBack}
                        // variant="outlined"
                        color="inherit"
                        disabled={step === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                </Box>
                <Box textAlign="center" sx={{ display: (!isLastStep() ? "none" : "block") }}>
                    <LoadingButton
                        type="submit"
                        size="large"
                        loading={loading}
                        loadingIndicator="Loading offers..."
                        variant="contained"
                        disabled={!isLastStep()}
                        sx={{ py: 2, pl: 4, pr: 4, mb: 4 }}>
                        Find Coverage Now
                    </LoadingButton >
                    <Typography variant="body2" textAlign="justify" sx={{ mb: 2 }}>
                        <input type="hidden" id="leadid_tcpa_disclosure" /><label htmlFor="leadid_tcpa_disclosure">By clicking <u>"Find Coverage Now"</u>, you provide your electronic signature as prior express consent and authorization for marketing purposes at Senior Healthcare USA to contact you regarding health insurance products and services, including Medicare Advantage plans, Medicare Supplement plans and/or Prescription Drug Plans at the phone number and email address you provided, using automated dialing systems including recurring auto-dialers, pre-recorded messages and text messages, even if your phone is a mobile number or is listed on any state, federal or corporate Do Not Call lists, and you are not required to give your consent as a condition of service or purchase. You understand that your telephone company may impose charges on you for these contacts, that you can revoke this consent at any time, and that the licensed insurance agents who may contact you are not connected with or endorsed by the U.S. government or the federal Medicare program. You also acknowledge that you have read and agree to the <Link href="/privacy-policy" target="_blank">Privacy Policy</Link> and <Link href="/terms-of-use" target="_blank">Terms &amp; Conditions</Link>. For SMS campaigns Text STOP to cancel and HELP for help. Message and data rates may apply. <Link href="/ccpa" target="_blank">California Residents</Link> If you would like contact us please call us at (877) 920-4527.</label>
                    </Typography>
                    <Typography sx={{
                        verticalAlign: 'middle',
                        display: 'inline-flex'
                    }} variant="body2" textAlign="center">
                        <LockIcon fontSize="small" /> Safe, Secure &amp; Confidential
                    </Typography>
                </Box>
            </form>
            <Snackbar />                                            
        </Box>
    );
};

export default SurveyForm;