import React from "react";
import ReactLoading from "react-loading";
import { Paper, Container, Box, Typography, Grid, Skeleton } from "@mui/material";
import ContentLoader from "react-content-loader"
import TextLoop from "react-text-loop";

export const Loading = (props: any) => {
    return (
        <Box sx={{ maxWidth: "600px", textAlign: "center", m: "auto" }}>
            <ContentLoader
                // key={index}
                speed={2}
                width={600}
                height={150}
                viewBox="0 0 600 150"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="160" cy="52" r="9" />
                <rect x="178" y="46" rx="5" ry="5" width="253" height="12" />
                <circle cx="160" cy="75" r="9" />
                <rect x="178" y="69" rx="5" ry="5" width="253" height="12" />
                <circle cx="160" cy="99" r="9" />
                <rect x="178" y="94" rx="5" ry="5" width="253" height="12" />
                <circle cx="160" cy="124" r="9" />
                <rect x="178" y="118" rx="5" ry="5" width="253" height="12" />
                <rect x="5" y="17" rx="0" ry="0" width="131" height="66" />
                <rect x="449" y="16" rx="0" ry="0" width="133" height="49" />
                <rect x="151" y="16" rx="0" ry="0" width="285" height="14" />
            </ContentLoader>

        </Box>
    )
}

export const ButtonLoading = () => (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ReactLoading
            type="spin"
            // color={styles.color.white}
            height="25px"
            width="25px"
        />
    </div>
);

export const PageLoading = () => (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ReactLoading
            type="spin"
            // color={styles.color.primary}
            height="30vh"
            width="25px"
        />
    </div>
);

export const OfferLoading = () => {

    return (
        <Container maxWidth="lg" sx={{ mt: 6, mb: 2 }}>
            <Paper elevation={6} sx={{ mb: 5, pb: 3, px: 3 }}>
                <Grid container spacing={3}>
                    <Grid item md={3} xs={12} textAlign="right" sx={{ position: "relative" }}>
                        <Skeleton variant="rectangular" width={"100%"} height={100} />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <TextLoop springConfig={{ stiffness: 180, damping: 8 }} interval={2000}>
                            <Typography variant="h4">Matching offers for you...</Typography>
                            <Typography variant="h4">Please wait...</Typography>
                            <Typography variant="h4">Loading all offers...</Typography>
                        </TextLoop>
                        <Typography>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </Typography>
                    </Grid>
                    <Grid item md={2} xs={12} textAlign="center">
                        <Skeleton variant="rectangular" width={"100%"} height={60} />
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
};