import { MouseEvent } from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const MedicalConditionValidator = ({medical_condition}: any) => (
    ! medical_condition || medical_condition === '' ? 'Please select if you have the following medical conditions.' : false
);

const MedicalConditions = ({ onChange }: ComponentProps) => {
           
    const { setValue, getValues } = useFormContext();

    const value = getValues('medical_condition');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {        
        setValue('medical_condition', value);        
        onChange('medical_condition', value, {
            autoNext: true
        });        
    };

    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "500px" }}>
            <Grid item xs={12} textAlign="center">                
                <ToggleButtonGroup                
                    value={value}
                    onChange={handleChange}
                    exclusive
                    color="primary"                                                            
                    size="large"
                >
                    <ToggleButton value="Yes" sx={{ px: { xs: 2, sm: 4 }, borderRadius: 20 }}>Yes</ToggleButton>
                    <ToggleButton value="No" sx={{ px: { xs: 2, sm: 4 }, borderRadius: 20 }}>No</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )
};

export default MedicalConditions;