import { MouseEvent } from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const MedicareEnrolledValidator = ({medicare_enrolled}: any) => (
    ! medicare_enrolled || medicare_enrolled === '' ? 'Please answer the question.' : false
);

const MedicareEnrolled = ({ onChange }: ComponentProps) => { 
    
    const { setValue, watch } = useFormContext();

    const value = watch('medicare_enrolled');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {                
        setValue('medicare_enrolled', value);        
        onChange('medicare_enrolled', value, {
            autoNext: true
        });                
    };

    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "500px" }}>
            <Grid item xs={12} textAlign="center">                
                <ToggleButtonGroup                
                    value={value}
                    onChange={handleChange}
                    exclusive
                    color="primary"                                                            
                    size="large"
                >
                    <ToggleButton value="Yes" sx={{ px: { xs: 4, sm: 8 }, borderRadius: 20 }}>Yes</ToggleButton>
                    <ToggleButton value="No" sx={{ px: { xs: 4, sm: 8 }, borderRadius: 20 }}>No</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )
};

export default MedicareEnrolled;