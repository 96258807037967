import { MouseEvent } from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ComponentProps } from ".";

import { useFormContext } from "react-hook-form";

export const CurrentlyInsuredValidator = ({currently_insured}: any) => (
    ! currently_insured || currently_insured === '' ? 'Please answer the question.' : false
);

const CurrentlyInsured = ({ onChange }: ComponentProps) => {            
    const { setValue, watch } = useFormContext();

    const value = watch('currently_insured');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {        
        setValue('currently_insured', value);        
        onChange('currently_insured', value);        
    };

    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "500px" }}>
            <Grid item xs={12} textAlign="center">                
                <ToggleButtonGroup                
                    value={value}
                    onChange={handleChange}
                    exclusive
                    color="primary"                                                            
                    size="large"
                >
                    <ToggleButton value="Yes" sx={{ py: 3, px: 6 }}>Yes</ToggleButton>
                    <ToggleButton value="No" sx={{ py: 3, px: 6 }}>No</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )
};

export default CurrentlyInsured;