import React from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  List,
  ListItemText,
  Link,
} from "@mui/material";
import CaliforniaConsumerAct from "./CaliforniaConsumerAct";

export default function PrivacyPolicy() {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          p: { xs: 3, md: 6 },
        }}
      >
        <Typography variant="h3">Privacy Notice</Typography>
        <Divider sx={{ my: 3 }} />
        <Typography variant="body1" paragraph>
          This privacy notice discloses the confidentiality practices for Global
          Digital Revenue, LLC. This notice pertains solely to any information
          gathered by this website. Below, you will find:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            • How the specific identifiable information collected from this
            website is utilized and with whom it could be shared.
          </ListItemText>
          <ListItemText>
            • The choices you have regarding use of your data.
          </ListItemText>
          <ListItemText>
            • What security practices are in place to protect your information
            from inappropriate use.
          </ListItemText>
          <ListItemText>
            • The way you can amend any inaccuracies found in your information.
          </ListItemText>
        </List>

        <Typography variant="h5" gutterBottom>
          How We Collect, Use, and Share Your Data
        </Typography>
        <Typography variant="body1" paragraph>
          The only information we have about you is what you voluntarily gave us
          via the website, email, or other direct contact from you.
        </Typography>
        <Typography variant="body1" paragraph>
          We use that data to get in touch with you, pertaining to the reason
          you contacted us. We <strong>DO NOT</strong> share your information
          with any third party other than with a licensed Insurance Agent, per
          your request.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Control Over and Access to Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          Please note that in the footer of every webpage and in every email we
          send, we include an “Unsubscribe” link you can choose at any time. If
          you wish to 1) read what data we have collected about you, 2) correct
          or change any data you provided us, 3) delete any information we have
          about you, or 4) express concerns over our use of your data, please
          use the “Contact Us” link in the footer.
        </Typography>
        {/* <List sx={{ pl: 4 }}>
          <ListItemText>
            - See what data we have about you, if any.
          </ListItemText>
          <ListItemText>
            - Change/correct any data we have about you.
          </ListItemText>
          <ListItemText>
            - Have us delete any data we have about you.
          </ListItemText>
          <ListItemText>
            - Express any concern you have about our use of your data.
          </ListItemText>
        </List> */}

        <Typography variant="h5" gutterBottom>
          Security Measures
        </Typography>
        <Typography variant="body1" paragraph>
          We are extremely protective of any information you share with us. Any
          and all data shared is guarded very closely by our use of high level
          encryption so that your information is transferred to and from the
          website in a secure manner. Note the “lock” icon in the address bar on
          your computer and the “https” in the webpage address. You should
          always look for those two things on any website you will be sending
          information, PARTICULARLY if transmitting any financial information
          (which we do not ask for nor collect).
        </Typography>
        <Typography variant="body1" paragraph>
          We are also careful to protect your data <i>offline</i>. Employees are
          granted access only to the accounts they need to work with to complete
          your requests. The servers, where we store any personally identifiable
          information, are kept in an ultra-secure environment, as well.
        </Typography>
        {/* <Typography variant="body1" paragraph>
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only employees who
          need the information to perform a specific job are granted access to
          personally identifiable information. The computers/servers in which we
          store personally identifiable information are kept in a secure
          environment.
        </Typography> */}

        <Typography variant="h5" gutterBottom>
          Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          Like most websites, we use “cookies.” These are bits of information
          that are stored on your computer’s hard drive. These cookies allow for
          information you’ve given us previously to be automatically filled in
          when you revisit our site and lessens the time it takes to load on
          your computer. Cookies in NO way are connected to any personally
          identifiable information on our site.
        </Typography>
        {/* <Typography variant="body1" paragraph>
          Some of our business partners may use cookies on our site (for
          example, advertisers). However, we have no access to or control over
          these cookies.
        </Typography>
        <Typography variant="body1" paragraph>
          We share aggregated demographic information with our partners and
          advertisers. This is not linked to any personal information that can
          identify any individual person.
        </Typography> */}

        <Typography variant="h5" gutterBottom>
          Associates
        </Typography>
        <Typography variant="body1" paragraph>
          We are associated with many Insurance companies in the US who are
          available to help you find the exact right coverage options to suit
          your budget. To see a list of our associates, click{" "}
          <Link href="/partners" underline="none">
            here
          </Link>
          .
        </Typography>

        <Typography variant="h5" gutterBottom>
          Full Disclosure
        </Typography>
        <Typography variant="body1" paragraph>
          Global Digital Revenue, LLC is a marketing firm bringing together
          consumers and knowledgeable Insurance Agents in your area. These
          agents can give you a list of options for your Medicare needs, taking
          into account where you live, what health problems you may have, etc.
          We are not affiliated with the government or any Federal agency.
        </Typography>
        <Typography variant="body1" paragraph>
          Our firm and our subsidiaries receive compensation from the Insurance
          Companies who have signed with us to be part of our comprehensive
          website offering consumers the best possible senior health care
          options.
        </Typography>
        <Typography variant="body1" paragraph fontWeight={500}>
          If, for any reason, you believe we are not abiding by this privacy
          policy, please contact us immediately.
        </Typography>
        <Typography variant="body1" paragraph>
          At any time you may opt out of any future emails, contacts, etc., by
          clicking on the “Unsubscribe” link in our footer below.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          Email: <a href="mailto:hello@seniorhealthcareusa.com">hello@seniorhealthcareusa.com</a><br />          
          Phone: (877) 920-4527
        </Typography>
        
        {/* <CaliforniaConsumerAct /> */}
      </Box>
    </Container>
  );
}
