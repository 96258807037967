import React from "react";
import Box from "@mui/material/Box";
import { Container, Grid, AppBar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  toolbarRoot: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
  },
});

const Header = (props) => {
  const { classes, handleToggleDrawer } = props;
  const Image = styled("img")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      display: "block",
      maxWidth: "350px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px",
    },
  }));

  const Anchor = styled("a")(({ theme }) => ({
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      color: "#41479b",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "250px",
    },
  }));

  const Span = styled("span")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  }));
  return (
    <AppBar position="static" color="inherit" sx={{ pt: 2, pb: 1 }}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item lg={6} md={6} xs={12}>
            <Link to={"/"}>
              <Image
                src={`/images/logo.png`}
                srcSet={`/images/logo.png`}
                alt="Senior HealthCare USA Logo"
                loading="lazy"
              />
            </Link>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Typography
              variant="h6"
              align="center"
              // textAlign={"right"}
              my={{ xs: 1, md: 4 }}
              sx={{ fontSize: "1.6rem", fontWeight: 600, px: { xs: 3, md: 6 } }}
            >
              <Anchor href="tel:(877) 817-1055">
                Call Now: <Span> (877) 817-1055</Span>
              </Anchor>
            </Typography>
            {/* <Image
              src={`https://www.myhealthcareamerica.com/wp-content/uploads/2021/10/Group-149.png`}
              srcSet={`https://www.myhealthcareamerica.com/wp-content/uploads/2021/10/Group-149.png`}
              loading="lazy"
              style={{ maxWidth: "409px", width: "100%", marginTop: "10px" }}
            /> */}
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
