import axios from "axios";
export const isBrowser = typeof window !== `undefined`;

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_ZIPPOPOTAM_BASE_URL}}`,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'                     
    }
});

export const getZip = async (zip: any) => await instance.get(`/${zip}`);