import React from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Hidden,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VerticalAlignMiddle from "../../components/VerticalAlignMiddle";

export default function ContentTwo() {
  const Image = styled("img")(({ theme }) => ({
    borderRadius: 10,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto",
      display: "block",
    },
  }));

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid
          item
          container
          columnSpacing={5}
          sx={{
            px: { xs: 3, md: 6 },
            my: { xs: 3, md: 6 },
          }}
        >
          <Grid item xs={12} md={5}>
            <VerticalAlignMiddle
              component={
                <Image
                  src={`images/content_image_3.png`}
                  srcSet={`images/content_image_3.png`}
                  alt="Senior HealthCare USA"
                  loading="lazy"
                  style={{ width: "100%" }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <VerticalAlignMiddle
              component={
                <Box
                  sx={{
                    mt: { xs: 3, sm: 3, md: 0 },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    color="text"
                    component="div"
                  >
                    Medicare Advantage Plans, Part C
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    These are plans provided by private insurers that offer the
                    same coverage as parts A and B (within Original Medicare).
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Enrolling in Part C with a private insurer gives you the
                    same coverage as your Original Health care plan and might be
                    better managed by your private insurer.
                  </Typography>
                </Box>
              }
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          sx={{
            px: { xs: 3, md: 6 },
            my: { xs: 3, md: 6 },
          }}
        >
          <Hidden mdUp>
            <Grid item xs={12} md={5}>
              <VerticalAlignMiddle
                component={
                  <Image
                    src={`images/content_image_4.png`}
                    srcSet={`images/content_image_4.png`}
                    alt="Senior HealthCare USA"
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                }
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={7}>
            <VerticalAlignMiddle
              component={
                <Box
                  sx={{
                    mt: { xs: 3, sm: 3, md: 0 },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    color="text"
                    component="div"
                  >
                    Medicare Supplement Plans
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    As in the name, Medigap Plans can help you pay less
                    out-of-pocket costs that Original Medicare didn’t cover. You
                    currently have at least 10 Medigap plan choices from private
                    insurance providers.
                  </Typography>
                  {/* <Typography variant="body1" color="text.secondary">
                    They are often referred to as Medigap Plans as they help pay
                    for out-of-pocket costs left by Original Medicare. There are
                    10 types of Medigap plans available from private insurance
                    companies.
                  </Typography> */}
                </Box>
              }
            />
          </Grid>
          <Hidden mdDown>
            <Grid item xs={12} md={5}>
              <VerticalAlignMiddle
                component={
                  <Image
                    src={`images/content_image_4.png`}
                    srcSet={`images/content_image_4.png`}
                    alt="Senior HealthCare USA"
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                }
              />
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          item
          container
          columnSpacing={5}
          sx={{
            px: { xs: 3, md: 6 },
            my: { xs: 3, md: 6 },
          }}
        >
          <Grid item xs={12} md={5}>
            <VerticalAlignMiddle
              component={
                <Image
                  src={`images/content_image_5.png`}
                  srcSet={`images/content_image_5.png`}
                  alt="Senior HealthCare USA"
                  loading="lazy"
                  style={{ width: "100%" }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <VerticalAlignMiddle
              component={
                <Box
                  sx={{
                    mt: { xs: 3, sm: 3, md: 0 },
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h4"
                    color="text"
                    component="div"
                  >
                    Medicare Part D – Prescription Drug Coverage
                  </Typography>
                  {/* <Typography
                    variant="body1"
                    color="text.secondary"
                    fontWeight={600}
                    gutterBottom
                  >
                    Medicare Part D helps make prescription drugs more
                    affordable.
                  </Typography> */}
                  <Typography variant="body1" color="text.secondary">
                    Prescription drug prices have skyrocketed; Medicare Part D
                    provides comprehensive prescription medication coverage and
                    must be applied for separately from your Original Medicare
                    plan. Or you can choose a Medicare Advantage plan that
                    includes prescription drug coverage.
                  </Typography>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
