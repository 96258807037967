import React from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  Grid,
  List,
  ListItemText,
  Link,
} from "@mui/material";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "white",
        p: 1,
        m: 1,
        borderRadius: 1,
        textAlign: "center",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

export default function CCPA() {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          p: { xs: 3, md: 6 },
        }}
      >
        <Typography variant="h3">California Privacy Notice</Typography>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h5" gutterBottom>
          California Privacy Notice
        </Typography>
        <Typography variant="h5" gutterBottom>
          Effective Date: January 1, 2020
        </Typography>
        <Typography variant="body1" paragraph>
          This California Privacy Notice supplements the information contained
          in our general Privacy Policy and applies solely to those visitors and
          users that are residents of the State of California (“consumer” or
          “you”). Global Digital Revenue (“we”, “us”, “our”) adopts this notice
          to comply with the California Consumer Privacy Act of 2018 (“CCPA”),
          as amended, and other California Privacy laws. Any terms defined by
          the CCPA have the same meaning when used in this notice.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect information that identifies, relates to, describes,
          references, is reasonably capable of being associated with, or could
          reasonably be linked, directly or indirectly, with a particular
          consumer or household (“personal information”). Personal information
          does not include publicly available information, deidentified
          information, aggregate information, or any information protected by
          other privacy laws and otherwise excluded from the scope of the CCPA.
        </Typography>
        <Typography variant="body1" paragraph>
          This table identifies the categories of personal information we have
          collected about consumers within the last 12 months:
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph fontWeight={600}>
              Category
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph fontWeight={600}>
              Examples
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              Collected
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              A.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Identifiers
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Real name, alias, postal address, unique personal identifier,
              online identifier, internet protocol address, email address,
              account name, social security number, driver’s license number,
              passport number, or other similar identifiers
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              B.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Personal information described in the California Customer Records
              statute (Cal. Civil Code §1798.80(e))
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              A name, signature, social security number, physical
              characteristics or description, address, telephone number,
              passport number, driver’s license or state identification card
              number, insurance policy number, education, employment, employment
              history, bank account number, credit card number, debit card
              number, or any other financial information, medical information,
              or health insurance information.
            </Typography>
            <Typography variant="body1" paragraph>
              Note: Personal information included in this category (1) does not
              include publicly available information that is lawfully made
              available to the general public from federal, state, or local
              government records, and (2) may overlap with other categories.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              C.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Protected classification characteristics under California or
              federal law
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Age (40 years or older), race, color, ancestry, national origin,
              citizenship, religion or creed, marital status, medical condition,
              physical or mental disability, sex (including gender, gender
              identity, gender expression, pregnancy or childbirth and related
              medical conditions), sexual orientation, veteran or military
              status, genetic information (including familial genetic
              information)
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              D.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Commercial Information
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Records of personal property, products or services purchased,
              obtained, or considered, or other purchasing or consuming
              histories or tendencies.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              E.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Biometric Information
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Physiological, biological, or behavioral characteristics,
              including deoxyribonucleic acid (DNA), which can be used, singly
              or in combination with each other or with other identifying data,
              to establish individual identity. Includes, but is not limited to,
              imagery of the iris, retina, fingerprint, face, hand, palm, vein
              patterns, and voice recordings, from which an identifier template,
              such as a faceprint, a minutiae template, or a voiceprint, can be
              extracted, and keystroke patterns or rhythms, gait patterns or
              rhythms, and sleep, health, or exercise data that contain
              identifying information.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              NO
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              F.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Internet or other electronic network activity information
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Browsing history, search history, information on a consumer’s
              interaction with a website, application, or advertisement
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              G.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Geolocation data
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Physical location or movements
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              H.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Audio, electronic, visual, thermal, olfactory, or similar
              information
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Electronic recording of customer or prospective customer calls.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              I.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Professional or employment-related information
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Current and prior job and salary history or performance
              evaluations.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              NO
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              J.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Education information that is not publicly available personally
              identifiable information as defined in the Family Educational
              Rights and Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part
              99).
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Education records directly related to a student maintained by an
              educational institution or party acting on its behalf, such as
              grades, transcripts, class lists, student schedules, student
              identification codes, student financial information, or student
              disciplinary records.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              NO
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph fontWeight={600}>
              K.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" paragraph>
              Inferences drawn from any other category of personal information
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" paragraph>
              Deriving information, data, assumptions, or conclusions from
              facts, evidence, or another source of information or data to
              create a profile reflecting a person’s preferences,
              characteristics, psychological trends, predispositions, behavior,
              attitudes, intelligence, abilities, and aptitudes.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" paragraph>
              YES
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h5" gutterBottom>
          Personal information does not include:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            - Publicly available information, which for purposes of the CCPA:
            <List sx={{ pl: 4 }}>
              <ListItemText>
                - means information that is lawfully made available from
                federal, state or local government records.
              </ListItemText>
              <ListItemText>
                - does not mean biometric information collected by a business
                about a consumer without the consumer’s knowledge.
              </ListItemText>
              <ListItemText>
                - does not include data used for a purpose that is not
                compatible with the purpose for which the data is maintained and
                made available in the government records or for which it is
                publicly maintained.
              </ListItemText>
              <ListItemText>
                - does not include consumer information that is de-identified or
                aggregate consumer information.
              </ListItemText>
            </List>
          </ListItemText>
          <ListItemText>
            - Information excluded from the CCPA’s scope, like:
            <List sx={{ pl: 4 }}>
              <ListItemText>
                - Medical information governed by the California Confidentiality
                of Medical Information Act (CMIA); protected health information
                covered by the Health Insurance Portability and Accountability
                Act of 1996 (HIPAA) and the Health Information Technology for
                Economic and Clinical Health Act (HITECH); and information
                collected as part of a clinical trial subject to the Federal
                Policy for the Protection of Human Subjects.
              </ListItemText>
              <ListItemText>
                - Personal information covered by certain sector-specific
                privacy laws, including the federal Fair Credit Reporting Act
                (FCRA), the federal Gramm-Leach-Bliley Act (GLBA), the
                California Financial Information Privacy Act (FIPA), and the
                Driver’s Privacy Protection Act of 1994 (DPPA).
              </ListItemText>
            </List>
          </ListItemText>
        </List>
        <Typography variant="h5" gutterBottom>
          How we Collect Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          We obtain the personal information listed above from the following
          sources:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            - Directly from you. For example, from forms you complete on our
            Website.
          </ListItemText>
          <ListItemText>
            - Indirectly from you. For example, from observing your actions on
            our Website via automatic data collection technologies.
          </ListItemText>
          <ListItemText>
            - From third parties. For example, from our affiliates and other
            third parties that send us leads.
          </ListItemText>
        </List>
        <Typography variant="h5" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may use or disclose the personal information we collect for one or
          more of the following business or commercial purposes:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            - To fulfill or meet the reason you provided the information;
          </ListItemText>
          <ListItemText>
            - To provide, support, personalize, and develop our Website,
            products, and services;
          </ListItemText>
          <ListItemText>
            - To create, maintain, customize, and secure your account with us,
            when applicable;
          </ListItemText>
          <ListItemText>
            - To provide you with support and to respond to your inquiries,
            including to investigate and address your concerns and monitor and
            improve our responses;
          </ListItemText>
          <ListItemText>
            - To personalize your Website experience and to deliver content and
            product and service offerings relevant to your interests, including
            targeted offers and ads through our Website, third-party sites, and
            via email or text message (with your consent, where required by
            law);
          </ListItemText>
          <ListItemText>
            - To help maintain the safety, security, and integrity of our
            Website, products and services, databases and other technology
            assets, and business;
          </ListItemText>
          <ListItemText>
            - For testing, research, analysis, and product development,
            including to develop and improve our Website, products, and
            services;
          </ListItemText>
          <ListItemText>
            - To respond to law enforcement requests and as required by
            applicable law, court order, or governmental regulations;
          </ListItemText>
          <ListItemText>
            - As described to you when collecting your personal information or
            as otherwise set forth in the CCPA
          </ListItemText>
        </List>
        <Typography variant="h5" gutterBottom>
          Sharing Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may disclose your personal information for a business purpose to
          third parties, such as our subsidiaries and affiliates, as well as
          non-affiliated third-parties with whom we partner to offer products
          and services to you.
        </Typography>
        <Typography variant="body1" paragraph>
          In the preceding 12 months, we have disclosed the following categories
          of personal information for a business purpose to the third parties
          identified above:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>- Category A: Identifiers</ListItemText>
          <ListItemText>
            - Category B: Personal information described in the California
            Customer Records statute (Cal. Civil Code §1798.80(e))
          </ListItemText>
          <ListItemText>- Category D: Commercial information</ListItemText>
          <ListItemText>
            - Category F: Internet or other similar network activity
          </ListItemText>
          <ListItemText>- Category G: Geolocation data</ListItemText>
          <ListItemText>
            - Category H: Audio, electronic, visual, thermal, olfactory, or
            similar information
          </ListItemText>
          <ListItemText>
            - Category K: Inferences drawn from any other category of personal
            information
          </ListItemText>
        </List>
        <Typography variant="h5" gutterBottom>
          Sale of Personal Information
        </Typography>
        <Typography variant="body1" paragraph>
          We may disclose certain information about you to consumer data
          resellers, advertising partners, affiliates, and other third parties
          for purposes that may be considered a “sale” under the CCPA. In the
          preceding 12 months, we have sold the following categories of personal
          information to third parties:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>- Category A: Identifiers</ListItemText>
          <ListItemText>
            - Category B: Personal information described in the California
            Customer Records statute (Cal. Civil Code §1798.80(e))
          </ListItemText>
          <ListItemText>- Category D: Commercial information</ListItemText>
          <ListItemText>
            - Category F: Internet or other similar network activity
          </ListItemText>
          <ListItemText>- Category G: Geolocation data</ListItemText>
          <ListItemText>
            - Category H: Audio, electronic, visual, thermal, olfactory, or
            similar information
          </ListItemText>
          <ListItemText>
            - Category K: Inferences drawn from any other category of personal
            information
          </ListItemText>
        </List>
        <Typography variant="h5" gutterBottom>
          Your California Privacy Rights and Choices
        </Typography>
        <Typography variant="body1" paragraph>
          If you are a California resident, the CCPA provides you with
          additional rights regarding our use of your personal information. This
          section describes your CCPA rights and explains how to exercise those
          rights.
        </Typography>
        <Typography variant="h5" gutterBottom fontStyle="italic">
          (1) Right to Access Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to request that we disclose certain information to
          you about our collection and use of your personal information during
          the 12-month period preceding your request. Once we receive and verify
          your request, we will disclose to you the information that you request
          and we are obligated to provide under the CCPA, which may include:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            - The categories of personal information we have collected about
            you;
          </ListItemText>
          <ListItemText>
            - The categories of sources from which we collected your personal
            information;
          </ListItemText>
          <ListItemText>
            - Our business or commercial purpose for collecting or selling your
            personal information;
          </ListItemText>
          <ListItemText>
            - The categories of personal information we have disclosed for a
            business purpose or sold about you;
          </ListItemText>
          <ListItemText>
            - The categories of third parties with whom we share or sell your
            personal information; and
          </ListItemText>
          <ListItemText>
            - The specific pieces of personal information we have collected
            about you.
          </ListItemText>
        </List>
        <Typography variant="h5" gutterBottom fontStyle="italic">
          (2) Right to Request Deletion
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to request that we delete some or all of the
          personal information that we have collected from you, subject to
          certain exceptions. Once we receive and verify your request, we will
          delete (and direct our service providers to whom we have furnished
          that information to also delete) your personal information from our
          (and their) records. With respect to the exceptions, we may deny your
          deletion request in whole or in part if retaining your personal
          information is necessary for us or our service providers to:
        </Typography>
        <Typography variant="body1" paragraph>
          Complete the transaction for which we collected the personal
          information, provide a good or service that you requested, take
          actions reasonably anticipated within the context of our ongoing
          business relationship with you, or otherwise perform our contract with
          you; Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities; Debug products to identify and repair errors that
          impair existing intended functionality. Exercise free speech, ensure
          the right of another consumer to exercise their free speech rights, or
          exercise another right provided for by law; Comply with the California
          Electronic Communications Privacy Act; Engage in public or
          peer-reviewed scientific, historical, or statistical research in the
          public interest that adheres to all other applicable ethics and
          privacy laws, when the information’s deletion may likely render
          impossible or seriously impair the research’s achievement, if you
          previously provided informed consent;
        </Typography>
        <Typography variant="body1" paragraph>
          Enable solely internal uses that are reasonably aligned with your
          expectations based on your relationship with us; Comply with a legal
          obligation; or
        </Typography>
        <Typography variant="body1" paragraph>
          Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </Typography>
        <Typography variant="h5" gutterBottom fontStyle="italic">
          (3) Right to Opt-Out
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to direct us not to sell your personal information
          to third parties. Any personal information about you that we sell to a
          third party cannot be sold by that third party unless you have
          received explicit notice and are provided with an opportunity to
          opt-out of the sale of your personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          You (or your authorized representative) may submit your request to
          opt-out by using the form available on this page.
        </Typography>
        <Typography variant="h5" gutterBottom fontStyle="italic">
          Exercising Access, Data Portability, and Deletion Rights
        </Typography>
        <Typography variant="body1" paragraph>
          To exercise the access, data portability, deletion and opt-out rights
          described above, please submit a verifiable consumer request to us by
          either:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>- Using the form available on this page</ListItemText>
        </List>
        <Typography variant="body1" paragraph>
          Only you or a person registered with the California Secretary of State
          that you authorize to act on your behalf, may make a verifiable
          consumer request related to your personal information. You may also
          make a verifiable consumer request on behalf of your minor child.
        </Typography>
        <Typography variant="body1" paragraph>
          You may only make a verifiable consumer request for access or data
          portability twice within a 12-month period. The verifiable consumer
          request must:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            - Provide sufficient information that allows us to reasonably verify
            you are the person about whom we collected personal information or
            an authorized representative. This includes your or the consumer’s:
            <List sx={{ pl: 4 }}>
              <ListItemText>- Name</ListItemText>
              <ListItemText>- Address</ListItemText>
              <ListItemText>- Email</ListItemText>
              <ListItemText>- Current Mailing Address</ListItemText>
              <ListItemText>- Alternate Phone</ListItemText>
              <ListItemText>- Alternate Mailing Address</ListItemText>
              <ListItemText>
                - Other personal information about you maintained by us.
              </ListItemText>
            </List>
          </ListItemText>
          <ListItemText>
            - Describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </ListItemText>
        </List>
        <Typography variant="body1" paragraph>
          Generally, we will not request additional information from you for
          purposes of verification. If, however, we cannot verify you identity
          from the information already maintained by us, we may request
          additional information from you, which shall only be used for the
          purposes of verifying you identity and seeking to exercise you rights
          under the CCPA, and for security or fraud-prevention purposes. We will
          delete any new personal information collected for the purposes of
          verification as soon as practical after processing your request,
          except as required to comply with the CCPA.
        </Typography>
        <Typography variant="body1" paragraph>
          We cannot respond to your request or provide you with personal
          information if we cannot verify your identity or authority to make the
          request and confirm the personal information relates to you. Making a
          verifiable consumer request does not require you to create an account
          with us. We may require authentication that is reasonable in light of
          the nature of the personal information requested and will only use
          personal information provided in a verifiable consumer request to
          verify the requestor’s identity or authority to make the request.
        </Typography>
        <Typography variant="body1" paragraph>
          Please note that we may continue to share your personal information
          with our affiliates and service providers for essential purposes
          described above and other such circumstances allowed under the CCPA.
          Opting out of the sale of personal information through the “Do Not
          Sell My Personal information” link does not mean that you will stop
          seeing ads, including some interest-based ads. To learn more about
          interest-based advertising across sites and additional opt-out
          choices, please see our general Privacy Policy.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Use of an Authorized Agent
        </Typography>
        <Typography variant="body1" paragraph>
          If you use an authorized agent to submit a request to know or a
          request to delete, we may require that you provide the authorized
          agent written permission and require the authorized agent to verify
          its own identity with us.
        </Typography>
        <Typography variant="body1" paragraph>
          A power of attorney to your authorized agent is an acceptable form of
          authorization.
        </Typography>
        <Typography variant="body1" paragraph>
          We may deny a request from an agent that does not submit proof that
          they have been authorized by you to act on your behalf.
        </Typography>
        <Typography variant="h5" gutterBottom>
          How we Verify your Request
        </Typography>
        <Typography variant="body1" paragraph>
          When you submit a request to delete, or a request to know, we verify
          your request by taking the following steps:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            - We may match the information you provided in your request with the
            personal information of the consumer already maintained by us; or
          </ListItemText>
          <ListItemText>
            - We may use a third-party identity verification service that
            complies with the CCPA;
          </ListItemText>
        </List>
        <Typography variant="body1" paragraph>
          When verifying a request, we consider the following factors:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>
            - The type, sensitivity, and value of the personal information
            collected and maintained about you;
          </ListItemText>
          <ListItemText>
            - The risk of harm to you posed by any unauthorized access or
            deletion;
          </ListItemText>
          <ListItemText>
            - The likelihood that fraudulent or malicious actors would seek the
            personal information;
          </ListItemText>
          <ListItemText>
            - Whether the personal information to be provided by you to verify
            your identity is sufficiently robust to protect against fraudulent
            requests or being spoofed or fabricated;
          </ListItemText>
          <ListItemText>
            - The manner in which we interact with you; and
          </ListItemText>
          <ListItemText>
            - The technology available for verification.
          </ListItemText>
        </List>
        <Typography variant="body1" paragraph>
          When you request to know categories of personal information, we verify
          your identity to a reasonable degree of certainty.
        </Typography>
        <Typography variant="body1" paragraph>
          When you request to delete, we verify your identity to a reasonable
          degree or a reasonably high degree of certainty depending on the
          sensitivity of the personal information and the risk of harm to you
          posed by unauthorized deletion.
        </Typography>
        <Typography variant="body1" paragraph>
          In all cases, we verify by matching the identifying information
          provided with the request to the personal information of the consumer
          already maintained by us.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Response Timing and Format
        </Typography>
        <Typography variant="body1" paragraph>
          Upon receiving a request to know or a request to delete, we will
          confirm receipt of the request within 15 days and provide information
          about how we will process the request.
        </Typography>
        <Typography variant="body1" paragraph>
          We strive to respond to a verifiable consumer request within 45 days
          of its receipt. If we require more time (up to 90 days), we will
          inform you of the reason and extension period in writing. We will
          deliver our written response by mail or electronically, at your
          option.
        </Typography>
        <Typography variant="body1" paragraph>
          Any disclosures we provide will only cover the 12-month period
          preceding the receipt of the verifiable consumer request. The response
          we provide will also explain the reasons we cannot comply with a
          request, if applicable. For these requests, we will select a format to
          provide your personal information that is readily useable and should
          allow you to transmit the information from one entity to another
          entity without hindrance.
        </Typography>
        <Typography variant="body1" paragraph>
          We do not charge a fee to process or respond to your verifiable
          consumer request unless it is excessive, repetitive, or manifestly
          unfounded. If we determine that the request warrants a fee, we will
          tell you why we made that decision and provide you with a cost
          estimate before completing your request.
        </Typography>
        <Typography variant="h5" gutterBottom fontStyle="italic">
          (4) Right to Non-Discrimination
        </Typography>
        <Typography variant="body1" paragraph>
          We will not discriminate against you for exercising any of your rights
          under the CCPA. For example, we will not:
        </Typography>
        <List sx={{ pl: 4 }}>
          <ListItemText>- Deny you goods or services.</ListItemText>
          <ListItemText>
            - Charge you different prices or rates for goods or services,
            including through the use of discounts or other benefits, or
            imposing penalties.
          </ListItemText>
          <ListItemText>
            - Provide you a different level or quality of goods or services.
          </ListItemText>
          <ListItemText>
            - Suggest that you will receive a different price or rate for goods
            or services or a different level or quality of goods or services.
          </ListItemText>
        </List>
        <Typography variant="body1" paragraph>
          Please know that we may charge you a different price or rate or
          provide a different level or quality of goods or services, if that
          difference is reasonably related to the value provided to you by your
          personal information.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Changes to Our Privacy Notice
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to amend this privacy notice at our discretion
          and at any time. When we make changes to this privacy notice (which
          will be updated at least once every 12 months), we will notify you
          through a notice on this page: Privacy Policy.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or comments about this notice, our Privacy
          Policy, the ways in which we collect and use your personal
          information, your choices and rights regarding such use, or wish to
          exercise your rights under California law, please do not hesitate to
          contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
          P.O. Box 12046, Wilmington, NC 28405
        </Typography>
        <Typography variant="body1" paragraph>
          You (or your authorized representative) may submit your request to
          opt-out by using the form available on this page:{" "}
          <Link href="/unsubscribe" underline="none" target="_blank">
            Unsubscribe Form
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}
