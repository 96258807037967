import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

function CallToActionOne(props) {
  const Anchor = styled("a")(({ theme }) => ({
    textDecoration: "none",
    color: "#000",
    "&:hover": {
      color: "#41479b",
    },
  }));
  const scrollToTop = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <Container maxWidth="xl">
      <Paper
        elevation={12}
        sx={{
          borderRadius: 10,
          p: { xs: 3, md: 6 },
          m: { xs: 3, md: 6 },
          textAlign: "center",
        }}
      >
        <Typography variant="h4" fontWeight={600} color="inherit">
          Dedicated, Licensed Medicare Agents are Standing By!
        </Typography>
        <Typography variant="h6" mb={3} color="inherit" gutterBottom>
          Get your questions answered today.
        </Typography>
        <Typography variant="h5" fontWeight={600} color="inherit" paragraph>
          <Anchor href="tel:(877) 817-1055">Call Now: (877) 817-1055</Anchor>,
          or
        </Typography>
        <Button
          variant="contained"
          sx={{
            borderRadius: 10,
            fontSize: 24,
            px: 6,
            py: 2,
            mb: 3,
          }}
          onClick={scrollToTop}
        >
          Get Your Free Quote TODAY!
        </Button>
        <Typography variant="body1" color="inherit" paragraph>
          (You are under no obligation to enroll)
        </Typography>
      </Paper>
    </Container>
  );
}

export default CallToActionOne;
