import React from "react";
import { Box } from "@mui/material";

import HeroBanner from "./HeroBanner";
import ContentOne from "./ContentOne";
import CallToActionOne from "./CallToActionOne";
import ContentTwo from "./ContentTwo";
import CallToActionTwo from "./CallToActionTwo";

export default function Home() {
  return (
    <Box fullWidth>
      <HeroBanner />
      <ContentOne />
      <CallToActionOne />
      <ContentTwo />
      <CallToActionTwo />
    </Box>
  );
}
