import Box from "@mui/material/Box";
const VerticalAlignMiddle = ({ component }) => (
  <Box
    sx={{
      display: "table",
      height: "100%",
    }}
  >
    <Box
      sx={{
        textAlign: "center",
        display: "table-cell",
        verticalAlign: "middle",
      }}
    >
      {component}
    </Box>
  </Box>
);
export default VerticalAlignMiddle;
