import { LinearProgressProps, LinearProgress, linearProgressClasses, Typography } from "@mui/material";
import styled from "@mui/styled-engine";
import { Box } from "@mui/system";


const ProgressBar = (props: LinearProgressProps & { steps: number, step: number }) => {

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#DDDDDD',
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#BABABA',
        },
    }));

    const value = ((100 / props.steps) * (props.step + 1)) || 100;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: 90 }}>
                <Typography variant="body2" color="text.secondary">Step {(props.step + 1)} to {props.steps}: </Typography>
            </Box>
            <Box sx={{ width: '100%', ml: 3, mr: 1 }}>
                <BorderLinearProgress variant="determinate" {...props} value={value} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default ProgressBar