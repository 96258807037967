import { MouseEvent } from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const PregnantValidator = ({pregnant}: any) => (
    ! pregnant || pregnant === '' ? 'Please answer the question.' : false
);

const Pregnant = ({ onChange }: ComponentProps) => {
    const { setValue, getValues } = useFormContext();

    const value = getValues('pregnant');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {        
        setValue('pregnant', value);        
        onChange('pregnant', value, {
            autoNext: true
        });        
    };    

    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "500px" }}>
            <Grid item xs={12} textAlign="center">
            <ToggleButtonGroup                
                    value={value}
                    onChange={handleChange}
                    exclusive
                    color="primary"                                                            
                    size="large"
                >
                    <ToggleButton value="Yes" sx={{ py: 3, px: 6 }}>Yes</ToggleButton>
                    <ToggleButton value="No" sx={{ py: 3, px: 6 }}>No</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )
};

export default Pregnant;