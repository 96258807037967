import { Grid, FormControl, MenuItem, Select, useMediaQuery, useTheme, SelectChangeEvent } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const CurrentCompanyValidator = ({current_company}: any) => (
    ! current_company || current_company === '' ? 'Please select your current insurance company.' : false
);

const CurrentCompany = ({ onChange }: ComponentProps) => {
    
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { setValue, watch } = useFormContext();
    
    const value = watch('current_company');
    
    const handleChange = (event: SelectChangeEvent) => {
        const name = event.target.name;
        const value = event.target.value;        
        setValue(name, value);
        onChange(name, value);     
    };

    const current_companies = [
        'AETNA',
        'American Family Insurance',
        'American Republic Insurance',
        'Assurant',
        'Atlantis Health Plan',
        'Blue Cross Blue Shield',
        'Celtic Insurance',
        'CIGNA',
        'Farm Bureau Insurance',
        'Golden Rule Insurance',
        'Health Net',
        'Health Plus of America',
        'Healthmarkets',
        'Humana',
        'InSphere',
        'Kaiser Permanente',
        'LifeWise Health Plan',
        'Metlife Insurance',
        'Oxford Health Plans',
        'PacifiCare',
        'State Farm Insurance',
        'Time Insurance',
        'Tufts Health Plan',
        'Unicare',
        'United American Insurance',
        'United HealthCare',
        'Vista Health Plan',
        'Other',
    ];
    
    return (
        <Grid container justifyContent="center" sx={{ m: "auto", my: 5, maxWidth: "500px" }} spacing={isSmall ? 0 : 2}>
            <Grid item md={6} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <FormControl fullWidth>
                    <Select
                        name="current_company"
                        value={value || ''}                        
                        onChange={handleChange}
                    >
                        <MenuItem value=''>- Please select -</MenuItem>
                        {current_companies.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
};

export default CurrentCompany;