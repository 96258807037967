import { MouseEvent } from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const MedicarePartsValidator = ({medicare_parts}: any) => (
    ! medicare_parts || medicare_parts === '' ? 'Please answer the question.' : false
);

const MedicareParts = ({ onChange }: ComponentProps) => {            
    const { setValue, watch } = useFormContext();

    const value = watch('medicare_parts');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {        
        setValue('medicare_parts', value);        
        onChange('medicare_parts', value, {
            autoNext: true
        });        
    };

    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "500px" }}>
            <Grid item xs={12} textAlign="center">                
                <ToggleButtonGroup                
                    value={value}
                    onChange={handleChange}
                    exclusive
                    color="primary"                                                            
                    size="large"
                >
                    <ToggleButton value="Medicare Part A" sx={{ px: { xs: 2, sm: 4 }, borderRadius: 20 }}>Medicare Part A</ToggleButton>
                    <ToggleButton value="Medicare Part B" sx={{ px: { xs: 2, sm: 4 }, borderRadius: 20 }}>Medicare Part B</ToggleButton>
                    <ToggleButton value="Both" sx={{ px: { xs: 4, sm: 8 }, borderRadius: 20 }}>Both</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )
};

export default MedicareParts;