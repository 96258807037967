import axios from "axios";
import { useCookies } from 'react-cookie';


export const isBrowser = typeof window !== `undefined`;

export const request = (type: string, apiVersion = "/api/v1") => {
    return axios.create({
        baseURL: `${process.env.REACT_APP_API_BASE_URL}${apiVersion}/${type}`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export const getURIencode = (params: any) => {
    return Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join('&');
}

export const token = () => {
    if (localStorage.getItem('Bearer')) {
        return localStorage.getItem('Bearer');
    }
    return false;
}


export const signup = async (uuid: string, closeBackdrop: Function, setCookie: Function) => {
    return await request('register')
        .post("/", { "uuid": uuid })
        .then(function (response: any) {
            if (response.data.success) {
                let token = response.data.data;
                setCookie('UUID', uuid, { path: '/', maxAge: 2592000 }); // 30 days
                localStorage.setItem(token?.token_type, token?.access_token);
            }
            closeBackdrop();
        });
}

export const check = async (data: any) => {
    return await request('check')
        .post("/", data)
        .then(function (response: any) {
            if (response.data.success) {
                let data = response.data.data;
                // if (!data.survey) {
                //     signup(uuid, closeBackdrop, setCookie)
                // } else {

                // }
                console.log(data)
                // setCookie('UUID', uuid, { path: '/', maxAge: 2592000 }); // 30 days
                // localStorage.setItem(token?.token_type, token?.access_token);
            }
        });
}

export const isUuidValid = async (uuid: string, closeBackdrop: Function, setCookie: Function) => {
    return await request('check/uuid')
        .post("/", { "uuid": uuid })
        .then(function (response: any) {
            if (response.data.success) {
                let data = response.data.data;
                if (!data.survey) {
                    signup(uuid, closeBackdrop, setCookie)
                } else {

                }
                console.log(data)
                // setCookie('UUID', uuid, { path: '/', maxAge: 2592000 }); // 30 days
                // localStorage.setItem(token?.token_type, token?.access_token);
            }
        });
}

function shuffle(array: Array<string>) {
    return array.sort(() => Math.random() - 0.5);
}

function randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomPingPostOption = async (submitOption = 50, offerOption = 50) => {
    //Percentage
    let options = [];
    const randomNumber = randomIntFromInterval(1, 100);

    for (var s = 0; s < submitOption; s++) {
        options.push("submitOption");
    }
    for (var o = 0; o < offerOption; o++) {
        options.push("offerOption");
    }
    localStorage.setItem('pingPostType', shuffle(options)[randomNumber]);
    return shuffle(options)[randomNumber];
    // console.log(shuffle(options), randomNumber, shuffle(options)[randomNumber]);
}