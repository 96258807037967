import React from "react";
import {
  Box,
  Typography,
  Container,
  Divider,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import CaliforniaConsumerAct from "./CaliforniaConsumerAct";
import VerticalAlignMiddle from "../../components/VerticalAlignMiddle";

export default function Unsubscribe() {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          p: { xs: 0, md: 6 },
        }}
      >
        {/* <VerticalAlignMiddle> */}
        <Paper
          sx={{
            maxWidth: 600,
            margin: "0 auto",
            padding: 5,
          }}
        >
          <Typography variant="h4"> Unsubscribe</Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" gutterBottom>
            Enter your email below to unsubscribe:
          </Typography>
          <TextField
            type="email"
            variant="standard"
            label="Email"
            fullWidth
            required
          />
          <Button
            variant="contained"
            sx={{
              fontSize: 20,
              px: 6,
              py: 2,
              mt: 3,
            }}
          >
            Submit
          </Button>
        </Paper>
        {/* </VerticalAlignMiddle> */}
      </Box>
    </Container>
  );
}
