import { useState, BaseSyntheticEvent } from "react";
import { Grid, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme, SelectChangeEvent } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const CurrentAddressValidator = ({address, address_city, address_state, address_zip}: any) => {
    if (! address || address === '')
        return 'Please enter your address.';
    
    if (! address_city || address_city === '')
        return 'Please enter your city.';

    if (! address_state || address_state === '')
        return 'Please select your state.';

    if (! address_zip || address_zip === '')
        return 'Please enter your zip.';

    return false;
};

const CurrentAddress = ({ onChange, submitResults }: ComponentProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));    
        
    const { setValue, getValues } = useFormContext();
    const [address, address_city, address_state, address_zip] = getValues(['address', 'address_city', 'address_state', 'address_zip']);
    const handleChange = (event: BaseSyntheticEvent | SelectChangeEvent) => setValue(event.target.name, event.target.value);
    const handleBlur = (event: BaseSyntheticEvent) => onChange(event.target.name, event.target.value);
    
    const states = [
        { label: "Alabama", value: 'AL' },
        { label: "Alaska", value: 'AK' },
        { label: "Arizona", value: 'AZ' },
        { label: "Arkansas", value: 'AR' },
        { label: "California", value: 'CA' },
        { label: "Colorado", value: 'CO' },
        { label: "Connecticut", value: 'CT' },
        { label: "Delaware", value: 'DE' },
        { label: "District Of Columbia", value: 'DC' },
        { label: "Florida", value: 'FL' },
        { label: "Georgia", value: 'GA' },
        { label: "Hawaii", value: 'HI' },
        { label: "Idaho", value: 'ID' },
        { label: "Illinois", value: 'IL' },
        { label: "Indiana", value: 'IN' },
        { label: "Iowa", value: 'IA' },
        { label: "Kansas", value: 'KS' },
        { label: "Kentucky", value: 'KY' },
        { label: "Louisiana", value: 'LA' },
        { label: "Maine", value: 'ME' },
        { label: "Maryland", value: 'MD' },
        { label: "Massachusetts", value: 'MA' },
        { label: "Michigan", value: 'MI' },
        { label: "Minnesota", value: 'MN' },
        { label: "Mississippi", value: 'MS' },
        { label: "Missouri", value: 'MO' },
        { label: "Montana", value: 'MT' },
        { label: "Nebraska", value: 'NE' },
        { label: "Nevada", value: 'NV' },
        { label: "New Hampshire", value: 'NH' },
        { label: "New Jersey", value: 'NJ' },
        { label: "New Mexico", value: 'NM' },
        { label: "New York", value: 'NY' },
        { label: "North Carolina", value: 'NC' },
        { label: "North Dakota", value: 'ND' },
        { label: "Ohio", value: 'OH' },
        { label: "Oklahoma", value: 'OK' },
        { label: "Oregon", value: 'OR' },
        { label: "Pennsylvania", value: 'PA' },
        { label: "Rhode Island", value: 'RI' },
        { label: "South Carolina", value: 'SC' },
        { label: "South Dakota", value: 'SD' },
        { label: "Tennessee", value: 'TN' },
        { label: "Texas", value: 'TX' },
        { label: "Utah", value: 'UT' },
        { label: "Vermont", value: 'VT' },
        { label: "Virginia", value: 'VA' },
        { label: "Washington", value: 'WA' },
        { label: "West Virginia", value: 'WV' },
        { label: "Wisconsin", value: 'WI' },
        { label: "Wyoming", value: 'WY' },
    ];

    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "400px" }} spacing={isSmall ? 0 : 2}>
            <Grid item md={12} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <TextField
                    label="Address"
                    name="address"
                    defaultValue={address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={submitResults?.errors?.address || false}
                    helperText={submitResults?.errors?.address || ""}
                    variant="outlined"
                    fullWidth
                    required                                        
                />
            </Grid>            
            <Grid item md={12} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <TextField
                    label="City"
                    name="address_city"
                    defaultValue={address_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    fullWidth
                    required 
                />
            </Grid>
            <Grid item md={6} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <FormControl fullWidth>
                    <InputLabel id="address_state">State</InputLabel>
                    <Select
                        label="State"
                        name="address_state"
                        defaultValue={address_state || ''}                        
                        onChange={handleChange}
                        onBlur={handleBlur}
                    >
                        {states.map((it, index) => <MenuItem key={index} value={it.value}>{it.label}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField
                    label="Zip"
                    name="address_zip"
                    defaultValue={address_zip}
                    onChange={handleChange}
                    onBlur={handleBlur}                
                    variant="outlined"
                    fullWidth
                    required                    
                />
            </Grid>
        </Grid >
    )
};

export default CurrentAddress;