import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
// import { ThemeProvider } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";

import Home from "./containers/Home/Home";
import {
  PrivacyPolicy,
  CCPA,
  Partners,
  TermsOfUse,
  Unsubscribe,
} from "./containers/Pages";
import Offers from "./containers/Offers";

import MainLayout from "./layouts/MainLayout";
import EmptyLayout from "./layouts/EmptyLayout";

import { getTheme } from "./containers/Setting/settingsReducer";

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: ["Archivo Black", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
    h2: {
      fontFamily: ["Archivo Black", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
    h3: {
      fontFamily: ["Archivo Black", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
    h4: {
      fontFamily: ["Archivo Black", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
    h5: {
      fontFamily: ["Archivo Black", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
    h6: {
      fontFamily: ["Archivo Black", "Helvetica", "Arial", "sans-serif"].join(
        ","
      ),
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
  },
});

const NotFound = () => {
  return <div>NotFound</div>;
};

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

export default function App() {
  // const theTheme = useSelector(getTheme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ height: "100vh" }}>
        <Router>
          <Switch>
            {/* <MainRoute path="/dashboard" component={Home} /> */}
            <MainRoute path="/privacy-policy" component={PrivacyPolicy} />
            <MainRoute path="/ccpa" component={CCPA} />
            <MainRoute path="/partners" component={Partners} />
            <MainRoute path="/terms-of-use" component={TermsOfUse} />
            <EmptyRoute path="/unsubscribe" component={Unsubscribe} />
            <MainRoute path="/offers" component={Offers} />
            <MainRoute exact path="/" component={Home} />
            <EmptyRoute component={NotFound} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}
