import { BaseSyntheticEvent, useState, lazy, Suspense, useEffect } from "react";
import { Box, Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { useFormContext } from "react-hook-form";

//import "../../theme/custom.css";

import { ComponentProps } from ".";
import { Loading } from "../ContentLoading";
const Fullname = lazy(() => import('./Fullname'));
const CurrentAddress = lazy(() => import('./CurrentAddress'));

export const ContactInformationValidator = ({firstname, lastname, address, address_city, address_state, address_zip, email, phone}: any, is_submitted: boolean = false) => {                                        
    const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    const phonePattern = new RegExp(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/);

    if (! email || email == "")
        return 'Please enter your email address.';                    
    if (!emailPattern.test(email))
        return 'Please enter valid email address.';                    
    if (!phone || phone == "")
        return 'Please enter your phone number.';                    
    if (!phonePattern.test(phone))
        return 'Please enter valid phone number.';                    

    if (is_submitted) {
        if (! firstname || firstname === '')
            return 'Please enter your First name.';                    
        if (! lastname || lastname === '')
            return 'Please enter your Last name.';                                            
        if (! address || address === '')
            return 'Please enter your address.';                        
        if (! address_city || address_city === '')
            return 'Please enter your city.';
        if (! address_state || address_state === '')
            return 'Please select your state.';
        if (! address_zip || address_zip === '')
            return 'Please enter your zip.';
    }
    
    return false;
}

const ContactInformation = ({ onChange, submitResults }: ComponentProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { setValue, getValues } = useFormContext();
    const [email, phone] = getValues(['email', 'phone']);

    const handleChange = (event: BaseSyntheticEvent) => setValue(event.target.name, event.target.value);
    const handleBlur = (event: BaseSyntheticEvent) => onChange(event.target.name, event.target.value);    

    return (
        <>
            {submitResults?.show_full && (<Box>
                <Suspense fallback={<Loading />}>
                    <Fullname onChange={onChange} submitResults={submitResults} />
                    <CurrentAddress onChange={onChange} submitResults={submitResults} />
                </Suspense>                
            </Box>)} 
            
            <Grid container sx={{ m: "auto", my: 5, maxWidth: "400px" }} spacing={isSmall ? 0 : 2}>
                <Grid item md={12} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                    <TextField
                        type="email"
                        label="Email"
                        name="email"
                        defaultValue={email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={submitResults?.errors?.email ? true : false}
                        helperText={submitResults?.errors?.email || ""}
                        fullWidth
                        required                        
                    />
                </Grid>
                <Grid item md={12} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                    <MuiPhoneNumber
                        fullWidth
                        error={submitResults?.errors?.phone || false}
                        helperText={submitResults?.errors?.phone || ""}
                        name="phone"
                        label="Phone Number"
                        data-cy="user-phone"
                        defaultCountry={"us"}
                        onlyCountries={['us', 'ca']}
                        placeholder={"(702) 123-4567"}
                        // disableDropdown={true}
                        disableAreaCodes={true}
                        disableCountryCode={true}
                        value={phone}
                        variant="outlined"
                        onChange={(value: any) => setValue('phone', value)}
                        onBlur={handleBlur}
                        sx={{
                            svg: {
                              height: "20px",
                            },
                          }}
                    />
                </Grid>
            </Grid>
        </>
    )
};

export default ContactInformation;