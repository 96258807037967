import { MouseEvent } from "react";
import { Grid, Hidden, ToggleButton, ToggleButtonGroup } from "@mui/material";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import WorkIcon from '@mui/icons-material/Work';
import ClearIcon from '@mui/icons-material/Clear';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const QualifyingLifeEventValidator = ({qualifying_life_event}: any) => (
    ! qualifying_life_event || qualifying_life_event === '' ? 'Please answer the question.' : false
);

const QualifyingLifeEvent = ({ onChange }: ComponentProps) => {
    
    const { setValue, getValues } = useFormContext();

    const value = getValues('qualifying_life_event');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {        
        setValue('qualifying_life_event', value);        
        onChange('qualifying_life_event', value, {
            autoNext: true
        });        
    };

    /*
    none
    marriage or divorce
    job loss / job change
    permanent move
    birth or adoption of a child
    other
    */

    return (
        <Grid container sx={{ m: "auto", my: 5 }}>
            <Grid item xs={12} textAlign="center">
                <Hidden smDown>
                    <ToggleButtonGroup                        
                        value={value}
                        onChange={handleChange}
                        exclusive
                        color="primary"
                        size="large"
                    >
                        <ToggleButton value="marriage or divorce" sx={{ py: 2, px: 2, width: 200, borderBottomLeftRadius: 0 }}>
                            <PeopleAltIcon sx={{ mr: 1 }} /> marriage or divorce
                        </ToggleButton>
                        <ToggleButton value="permanent move" sx={{ py: 2, px: 2, width: 200 }}>
                            <LocalShippingIcon sx={{ mr: 1 }} /> permanent move
                        </ToggleButton>
                        <ToggleButton value="other" sx={{ py: 2, px: 2, width: 200, borderBottomRightRadius: 0 }}
                        > <HelpOutlineIcon sx={{ mr: 1 }} /> others
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup                        
                        value={value}
                        onChange={handleChange}
                        exclusive
                        color="primary"
                        size="large"
                    >
                        <ToggleButton value="birth or adoption of a child" sx={{ py: 2, px: 2, width: 200, borderTop: "none", borderTopLeftRadius: 0 }}>
                            <ChildFriendlyIcon sx={{ mr: 1 }} /> birth or adoption of a child
                        </ToggleButton>
                        <ToggleButton value="job loss / job change" sx={{ py: 2, px: 2, width: 200, borderTop: "none" }}>
                            <WorkIcon sx={{ mr: 1 }} /> job loss / job change
                        </ToggleButton>
                        <ToggleButton value="none" sx={{ py: 2, px: 2, width: 200, borderTop: "none", borderTopRightRadius: 0 }}>
                            <ClearIcon sx={{ mr: 1 }} /> None of these apply
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Hidden>
                <Hidden smUp>
                    <ToggleButtonGroup                        
                        value={value}
                        onChange={handleChange}
                        exclusive
                        color="primary"
                        size="large"
                    >
                        <ToggleButton value="marriage or divorce" sx={{ py: 2, px: 2, width: 150, borderBottomLeftRadius: 0 }}>
                            <PeopleAltIcon sx={{ mr: 1 }} /> marriage or divorce
                        </ToggleButton>
                        <ToggleButton value="permanent move" sx={{ py: 2, px: 2, width: 150, borderBottomRightRadius: 0 }}>
                            <LocalShippingIcon sx={{ mr: 1 }} /> permanent move
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup                        
                        value={value}
                        onChange={handleChange}
                        exclusive
                        color="primary"
                        size="large"
                    >
                        <ToggleButton value="birth or adoption of a child" sx={{ py: 2, px: 2, width: 150, borderTop: "none", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                            <ChildFriendlyIcon sx={{ mr: 1 }} /> birth or adoption of a child
                        </ToggleButton>
                        <ToggleButton value="job loss / job change" sx={{ py: 2, px: 2, width: 150, borderTop: "none", borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                            <WorkIcon sx={{ mr: 1 }} /> job loss / job change
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup                        
                        value={value}
                        onChange={handleChange}
                        exclusive
                        color="primary"
                        size="large"
                    >
                        <ToggleButton value="other" sx={{ py: 2, px: 2, width: 150, borderTop: "none", borderTopLeftRadius: 0 }}
                        > <HelpOutlineIcon sx={{ mr: 1 }} /> others
                        </ToggleButton>
                        <ToggleButton value="none" sx={{ py: 2, px: 2, width: 150, borderTop: "none", borderTopRightRadius: 0 }}>
                            <ClearIcon sx={{ mr: 1 }} /> None of these apply
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Hidden>
            </Grid>
        </Grid>
    )
};

export default QualifyingLifeEvent;