import { MouseEvent } from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const GenderValidator = ({gender}: any) => (
    ! gender || gender === '' ?  'Please select your gender.' : false
);

const Gender = ({ onChange }: ComponentProps) => {    
    
    const { setValue, watch } = useFormContext();

    const value = watch('gender');

    const handleChange = (event: MouseEvent<HTMLElement>, value: string) => {        
        setValue('gender', value);        
        onChange('gender', value, {
            autoNext: true
        });        
    };
   
    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "500px" }}>
            <Grid item xs={12} textAlign="center">
                <ToggleButtonGroup
                    color="primary"
                    value={value}
                    exclusive
                    onChange={handleChange}
                    size="large"
                >
                    <ToggleButton value="MALE" sx={{ p: 4, borderRadius: 50 }}> <MaleIcon sx={{ mr: 1 }} /> Male</ToggleButton>
                    <ToggleButton value="FEMALE" sx={{ p: 4, borderRadius: 50 }}> <FemaleIcon sx={{ mr: 1 }} /> Female</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )
};

export default Gender;