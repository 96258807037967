const _window: any = window || undefined;
const MediaAlphaExchange__load = _window.MediaAlphaExchange__load || undefined;
export const MediaAlphaExchange__fetchUserID = _window.MediaAlphaExchange__fetchUserID;
export const MediaAlphaExchange__success = _window.MediaAlphaExchange__success;
export const MediaAlphaExchange__error = _window.MediaAlphaExchange__error;
export const MediaAlphaExchange__click = _window.MediaAlphaExchange__click;
export const MediaAlphaExchange__enableDirectLinks = _window.MediaAlphaExchange__enableDirectLinks;
export const MediaAlphaExchange__disableDirectLinks = _window.MediaAlphaExchange__disableDirectLinks;
export const MediaAlphaExchange__loadDirectLink = _window.MediaAlphaExchange__loadDirectLink;
export const setMediaAlphaExchange = (data: any) => _window.MediaAlphaExchange = data;

export default MediaAlphaExchange__load;