import { BaseSyntheticEvent } from "react";
import { Grid, TextField, useMediaQuery, useTheme } from "@mui/material";
import { ComponentProps } from ".";

import { useFormContext } from "react-hook-form";

export const FullnameValidator = ({firstname, lastname}: any) => {
    if (! firstname || firstname === '')
        return 'Please enter your First name.';
    
    if (! lastname || lastname === '')
        return 'Please enter your Last name.';                    

    return false;
};

const Fullname = ({ onChange, submitResults }: ComponentProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { setValue, getValues } = useFormContext();

    const [firstname, lastname] = getValues(['firstname', 'lastname']);
    
    const handleChange = (event: BaseSyntheticEvent) => setValue(event.target.name, event.target.value);
    const handleBlur = (event: BaseSyntheticEvent) => onChange(event.target.name, event.target.value);

    return (
        <Grid container sx={{ m: "auto", my: 5, maxWidth: "400px" }} spacing={isSmall ? 0 : 2}>
            <Grid item md={6} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <TextField
                    label="First Name"
                    name="firstname"
                    defaultValue={firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={submitResults?.errors?.firstname || false}
                    helperText={submitResults?.errors?.firstname || ""}
                    variant="outlined"
                    fullWidth
                    required                                                            
                />
            </Grid>
            <Grid item md={6} xs={12}>
                <TextField                    
                    label="Last Name"
                    name="lastname"
                    defaultValue={lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={submitResults?.errors?.lastname || false}
                    helperText={submitResults?.errors?.lastname || ""}
                    variant="outlined"
                    fullWidth
                    required                    
                />
            </Grid>
        </Grid>
    )
};

export default Fullname;