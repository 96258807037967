import { ContactInformationValidator } from "../components/Survey/questions/ContactInformation";
import { CreditRatingValidator } from "../components/Survey/questions/CreditRating";
import { CurrentAddressValidator } from "../components/Survey/questions/CurrentAddress";
import { CurrentCompanyValidator } from "../components/Survey/questions/CurrentCompany";
import { CurrentlyInsuredValidator } from "../components/Survey/questions/CurrentlyInsured";
import { DateOfBirthValidator } from "../components/Survey/questions/DateOfBirth";
import { FullnameValidator } from "../components/Survey/questions/Fullname";
import { GenderValidator } from "../components/Survey/questions/Gender";
import { MedicalConditionValidator } from "../components/Survey/questions/MedicalConditions";
import { PregnantValidator } from "../components/Survey/questions/Pregnant";
import { QualifyingLifeEventValidator } from "../components/Survey/questions/QualifyingLifeEvent";
import { TobaccoValidator } from "../components/Survey/questions/Tobacco";
import { MedicareEnrolledValidator } from "../components/Survey/questions/MedicareEnrolled";
import { MedicarePartsValidator } from "../components/Survey/questions/MedicareParts";
import { CoverageTypeValidator } from "../components/Survey/questions/CoverageType";

const validators: any = {
    CurrentlyInsured: CurrentlyInsuredValidator,
    CurrentCompany: CurrentCompanyValidator,
    DateOfBirth: DateOfBirthValidator,
    Gender: GenderValidator,
    CreditRating: CreditRatingValidator,
    CurrentAddress: CurrentAddressValidator,
    ContactInformation: ContactInformationValidator,
    Fullname: FullnameValidator,
    MedicalConditions: MedicalConditionValidator,
    Pregnant: PregnantValidator,
    QualifyingLifeEvent: QualifyingLifeEventValidator,
    Tobacco: TobaccoValidator,
    MedicareEnrolled: MedicareEnrolledValidator,
    MedicareParts: MedicarePartsValidator,
    CoverageType: CoverageTypeValidator,
};

const Validator = (component: string) => {
    const validate = validators[component] || undefined;
    if (validate)
        return { component, validate };
        
    return validate;
}

export default Validator;