import { useState, useEffect } from "react";
import { Container, Typography, Paper, Box, Grid, Button, Link } from "@mui/material";
import { request, token, getURIencode } from '../utils/client'
import { useCookies } from 'react-cookie';
import $ from 'jquery';
import MediaAlphaExchange__load, { setMediaAlphaExchange } from "../utils/MediaAlphaExchange";

const Offers = () => {

    const [bannerAddress, setBannerAddress] = useState("");
    const [cookies, setCookie, removeCookie] = useCookies<any>(['S']);
    const survey = cookies?.S?.survey;
    const url = new URL(window.location.href);
    const zip = url.searchParams.get("zip");
    const sub_1 = url.searchParams.get("sub_1");
    const cid = url.searchParams.get("cid");    
    const stage = url.searchParams.get('stage');      
    const leadid_id = cookies?.leadid_id;
    const birthdate = survey.birth_year + "-" + survey.birth_month + "-" + survey.birth_day;     

    useEffect(() => {        
        if (survey?.address_state && survey?.address_city) {
            setBannerAddress(`${survey?.address_city}, ${survey?.address_state}`);
        } else {
            request('location')
                .get("")
                .then(function (response: any) {
                    if (response.data.success) {
                        const address = response.data.data;
                        setBannerAddress(`${address.address_city}, ${address.address_state}`);
                    }
                });
        }

        try {
            setMediaAlphaExchange({
                "data": {
                    "zip": zip ? zip : "91210",            
                    "address" : survey.address ?? "",
                    "birth_date" : birthdate ?? "",
                    "city" : survey.address_city,
                    "gender" : survey.gender == "MALE" ? "M" : "F",
                    "credit_rating" : survey.credit_rating,
                    "leadid_id" : leadid_id,
                },
                "placement_id": "CagNMkaX99gVkAXje5Fu7Ke_AtzTBw",
                "sub_1": sub_1 ?? "",
                "sub_2": stage ?? 1,
                "sub_3": cid ?? "",
                "type": "ad_unit",
                "ua_class": "auto",
                "version": 17
              });
              MediaAlphaExchange__load('mediaalpha_placeholder');
        } catch (err) {
            console.log("Request Error: "+ err);
        }        
    }, []);

    // const ping = async () => {
    //     var url_string = (window.location.href).toLowerCase();
    //     var url = new URL(url_string);

    //     await request('ping')
    //         .post("", getURIencode({
    //             "local": (new Date()).getHours(),
    //             'sub_1': cookies.subs ? cookies.subs.sub_1 : "",
    //             'sub_3': cookies.subs ? cookies.subs.sub_3 : "",
    //             'stage': url?.searchParams?.get("stage") ?? 1
    //         }), {
    //             headers: {
    //                 'Authorization': 'Bearer ' + token()
    //             }
    //         })
    //         .then(function (response: any) {
    //             if (JSON.parse(response.data.buyers).length) {
    //                 openConnectMe();
    //             }
    //             setCookie('ping', response.data.ping_id);
    //         });
    // }

    $(function () {                                 
        if (!$('#mediaalpha_placeholder').html().length && !cookies.offerReload) {
            setCookie('offerReload', true);
            console.log($('#mediaalpha_placeholder').html().length)
            window.location.reload();
        }
    });

    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            <Box sx={{
                py: 4,
                bgcolor: "#004c9e",
                color: "#fff",
                textAlign: "center",
                boxShadow: 3
            }}>
                <Typography variant="h5">
                    You've been matched with Health insurance providers in {bannerAddress}.
                </Typography>
                <Typography variant="h6">
                    Click below to get your Health Quotes!
                </Typography>
            </Box>
            
            <Container maxWidth="lg" sx={{ mt: 6, mb: 2 }}>
                <div id="mediaalpha_placeholder"></div>                
            </Container>
        </Box >);
}

export default Offers;