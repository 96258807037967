import { Grid, FormControl, MenuItem, Select, useMediaQuery, useTheme, SelectChangeEvent } from "@mui/material";
import { ComponentProps } from ".";
import { useFormContext } from "react-hook-form";

export const CreditRatingValidator = ({credit_rating}: any) => (
    ! credit_rating || credit_rating === '' ? 'Please select your Credit Rating.' : false
);

const CreditRating = ({ onChange }: ComponentProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { setValue, watch } = useFormContext();
    
    const value = watch('credit_rating');

    const handleChange = (event: SelectChangeEvent) => {
        const name = event.target.name;
        const value = event.target.value;        
        setValue(name, value);
        onChange(name, value);     
    };

    const credit_ratings = [
        'excellent',
        'good',
        'average',
        'below average',
        'poor',
    ];

    return (
        <Grid container justifyContent="center" sx={{ m: "auto", my: 5, maxWidth: "500px" }} spacing={isSmall ? 0 : 2}>
            <Grid item md={6} xs={12} sx={{ mb: (isSmall ? 2 : 0) }}>
                <FormControl fullWidth>
                    <Select
                        name="credit_rating"
                        value={value || ''}                        
                        onChange={handleChange}
                        style={{textTransform: 'capitalize'}}
                    >
                        <MenuItem key={-1} value=''>- Please select -</MenuItem>
                        {credit_ratings.map((value, index) => <MenuItem key={index} value={value} style={{textTransform: 'capitalize'}}>{value}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
};

export default CreditRating;