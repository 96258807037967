import axios from "axios";
export const isBrowser = typeof window !== `undefined`;

const API_VERSION = 'api/v1';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'                       
    }    
});

export const getToken = () => {
    if (localStorage.getItem('Bearer')) {
        return localStorage.getItem('Bearer');
    }
    return false;
}

instance.interceptors.request.use((config: any) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

const getPath = (path: string) => (`/${API_VERSION}/${path}`);
const encodeParams = (params: any) => Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

export const getCsrfCookie = async (params: any) => await instance.get('/sanctum/csrf-cookie', params);
export const postRegister = async (params: any) => await instance.post(getPath('register'), encodeParams(params));
export const postCheck = async (params: any) => await instance.post(getPath('check'), encodeParams(params));
export const getLocation = async () => await instance.get(getPath('location'));
export const patchSignup = async ({id, ...params}: any) => await instance.patch(getPath('signups/'+id), encodeParams(params));
export const putSignup = async ({id, ...params}: any) => await instance.put(getPath('signups/'+id), encodeParams(params));
export const getSurveys = async ({id, ...params}: any) => await instance.get(getPath(`surveys/${id}/questions`), {params});
export const requestPing = async (params: any) => await instance.post(getPath('ping'), encodeParams(params));