import { useState, SyntheticEvent } from "react";
import { Alert } from "@mui/material";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide';

interface StateProps extends SnackbarOrigin {
    open: boolean;
    action: any;
    message: string;
}

const useSnackbar = () => {

    const [state, setState] = useState<StateProps>({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        action: "error",
        message: ""
    });

    const { vertical, horizontal, open, action, message } = state;

    const handleOpen = (action: any, message: string) => setState({
        ...state,
        open: true,
        action: action,
        message: message
    });
    

    const handleClose = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') return;        
        setState({
            ...state,
            open: false
        });
    };

    function SlideTransition(props: SlideProps) {
        return <Slide {...props} direction="up" />;
    }

    const SnackbarComponent = () => (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            TransitionComponent={SlideTransition}>
            <Alert onClose={handleClose} severity={action} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>)

    return { Snackbar: SnackbarComponent, open: handleOpen, close: handleClose }
}

export default useSnackbar;